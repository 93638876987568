import { GENERIC_STATUS_ICON_COL, STANDARD_COLS } from '../genericTableColumns';
import { addBaseProperties, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const transformTetherExtensionDringExtender = (items) => {
    return items.map(addBaseProperties).map(addLaunchReturn);
};
export const TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS = [...STANDARD_COLS];
export const ACTIVITY_TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    GENERIC_STATUS_ICON_COL
];
