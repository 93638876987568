import { HardwareType, HardwareTypeDisplay } from '~/types';
import { alertableStatusDisplay, dateDisplay } from '~/utils';
import { addAttributeDateDisplays, addBaseProperties, addEvaEvents, addFemurComments, addLaunchReturn } from '../mappers';
import { buildNotOkIcon, buildOkIcon } from '../ui/buildIconAsString';
import { FEMUR_UIA_TABLE_HEADERS } from './uia';
export const FEMUR_IEU_SCU_UIA_EMU_TABLE_HEADERS = [
    {
        text: 'Hardware Type',
        value: '_hardwareType',
        sortable: true
    },
    ...FEMUR_UIA_TABLE_HEADERS
];
export const transformIeuScuUiaEmuForFemur = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addEvaEvents)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map((item) => {
        const attributes = item.attributes;
        const status = item.status;
        const _statusIcon = attributes.verificationDate
            ? buildOkIcon('Has Verification Date')
            : buildNotOkIcon('DOES NOT HAVE Verification Date');
        let _waterCirculationDueDate = dateDisplay(status?.scrubDueDate?.value);
        _waterCirculationDueDate = alertableStatusDisplay(status?.scrubDueDate, _waterCirculationDueDate);
        let _iodinateDueDate = dateDisplay(status?.iodinateDueDate?.value);
        _iodinateDueDate = alertableStatusDisplay(status?.iodinateDueDate, _iodinateDueDate);
        let _lastIodinateDate = dateDisplay(status.lastIodinateDate?.value);
        _lastIodinateDate = alertableStatusDisplay(status?.lastIodinateDate, _lastIodinateDate);
        let _postEvaScrubDueDate = dateDisplay(status?.postEvaScrubDueDate?.value);
        _postEvaScrubDueDate = alertableStatusDisplay(status?.postEvaScrubDueDate, _postEvaScrubDueDate);
        let _preEvaScrubDueDate = dateDisplay(status?.preEvaScrubDueDate?.value);
        _preEvaScrubDueDate = alertableStatusDisplay(status?.preEvaScrubDueDate, _preEvaScrubDueDate);
        let _scrubDate = dateDisplay(status?.lastScrubDate?.value);
        _scrubDate = alertableStatusDisplay(status?.lastScrubDate, _scrubDate);
        const _item = Object.assign({}, item, {
            _hardwareType: HardwareTypeDisplay.get(item.subType),
            // Remove comments from EMUs since they will be in separate table
            _comments: item.subType === HardwareType.EMU ? '<em>See EMU table above</em>' : item._comments,
            _iodinateDueDate: _iodinateDueDate,
            _lastActualPostScrubDate: _lastIodinateDate,
            _postEVANLTDate: _postEvaScrubDueDate,
            _preEvaScrubNetDate: _preEvaScrubDueDate,
            _scrubDate,
            _statusIcon,
            _waterCirculationDueDate
        });
        return _item;
    });
};
