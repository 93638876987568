export * from './arrayCheckSet';
export * from './displays';
export * from './getDateRangeInputObject';
export * from './getNumericalRangeAsStrings';
export * from './markdownToHtml';
export * from './numPad';
export * from './rangeToListOfNumbers';
export * from './removePropertyFromObject';
export * from './sanitize';
export * from './sortObjectBy';
export * from './sortObjectNumericallyBy';
export * from './stripHtmlFromString';
export * from './stripUnderscorePropsFromObject';
export * from './transformHardwaresToTableFormat';
export const booleanDisplay = (bool, title) => {
    const _title = title || bool?.toString();
    if (bool === null || bool === undefined) {
        return `<i class="fa-2x fad fa-minus-circle" title="${_title}"></i>`;
    }
    return bool
        ? `<i class=" fa-2x fad fa-check-circle success--text" title="${_title}"></i>`
        : `<i class=" fa-2x fas fa-ban secondary--text" title="${_title}"></i>`;
};
