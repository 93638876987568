import { ActivityTypeDisplay, CommentType, EntityType, EvaReadinessDisplay, HardwareTypeDisplay } from '~/types';
import { asBuiltNumberDisplay, dateDisplay, numberDisplay, serialNumberDisplay } from '..';
import { transformActivities } from '../constants';
import { alertableStatusDisplay, markdownToHtml, sanitize, sizeDisplay, sortObjectBy, stringListDisplay } from '../helpers';
import { buildChipAsString, buildItemInstanceChip } from '../ui';
import { buildEvaReadinessIconAsString, buildIconAsString, buildIconWithLabelAsString, buildNotOkIcon } from '../ui/buildIconAsString';
import { CosmicIcons } from '../ui/CosmicIcons';
import { getColorFromBatteryState } from '../ui/getColorFromBatteryState';
// this is a helper NOT intended as a TS mapper function (because it doesn't
// accept map params)
export const commentsToHtml = (item, commentType = null) => {
    const _item = Object.assign({}, item);
    const commentsList = commentType
        ? _item.comments?.filter((comment) => comment.subType === commentType)
        : _item.comments;
    if (!commentsList || commentsList.length === 0) {
        _item._comments = buildChipAsString({
            chipIconClass: CosmicIcons[EntityType.COMMENT],
            chipText: 'No Comments logged.',
            color: EntityType.COMMENT,
            iconColor: EntityType.COMMENT
        });
        return _item;
    }
    const html = (commentsList || [])
        .map((comment) => {
        if (!comment) {
            return '';
        }
        const html = `<li class="my-2">
        ${markdownToHtml(sanitize(comment.commentText))}

        <small>
          Last Update: <strong>${dateDisplay(comment.modifiedDate || comment.createdDate)}</strong>
        </small>

        <hr/>
      </li>`;
        return html;
    })
        .join('');
    // setting the min-width here is necessary because the width property of
    // IAppTableHeader only has an effect if the table fits on the page *without*
    // needing horizontal scrolling.
    _item._comments = `<ul class="pa-0 list-style-none" style="min-width: 350px">${html}</ul>`;
    return _item;
};
export const addActivitySubType = (item) => {
    const _item = Object.assign({}, item, {
        _subType: ActivityTypeDisplay.get(item.subType) || '-'
    });
    return _item;
};
export const addActivities = (item) => {
    const _item = Object.assign({}, item);
    _item.activities = item.subType ? transformActivities(item.activities, item.subType) : [];
    return _item;
};
/**
 * This function returns a function that can be passed to Array.prototype.map.
 * It is meant to map over items in a table and modify comments which are
 * "alertable" (ie, they have an `alertType`). It assumes that `item` has a
 * status object was various properties. You need to pass the appropriate key on
 * the status object as well as the key that you are using for the display
 * value. Given the two parameters, the function will wrap the existing display
 * value inside of a <span> with the appropriate class name for coloring text.
 *
 * Note that is only works on status objects where the value is a string or a
 * number.
 *
 * @param statusProp - the key on `item.status` which points to the appropriate
 * object
 * @param displayProp - the key that represents the display value for this
 * column
 * @returns a mapper function
 *
 * @example Here's an example: Assuming that item.status.batteryVoltage is the
 * property you want to modify, you would pass in `batteryVoltage` and (most
 * likely) `_batteryVoltage`.
 */
export const addAlertableValue = (statusProp, displayProp) => (item) => {
    // I tried to type `item` sort of generically but it caused all sort of
    // problems for the consumer
    const alertType = item?.status?.[statusProp]?.alertType ?? null;
    if (!alertType) {
        return item;
    }
    return Object.assign({}, item, {
        [displayProp]: alertableStatusDisplay(item.status[statusProp], item[displayProp] ?? null)
    });
};
export const addAutocycleDueDate = (item) => {
    let _autocycleDueDate = dateDisplay(item.status?.autocycleDueDate?.value);
    _autocycleDueDate = alertableStatusDisplay(item.status?.autocycleDueDate, _autocycleDueDate);
    const _item = Object.assign({}, item, {
        _autocycleDueDate
    });
    return _item;
};
export const addAttributeDateDisplays = (item) => {
    const _item = Object.assign({}, item);
    if (item.attributes) {
        Object.entries(item.attributes).forEach(([propertyName, propertyValue]) => {
            if (propertyName.endsWith('Date')) {
                _item[`_${propertyName}`] = dateDisplay(propertyValue);
            }
        });
    }
    return _item;
};
export const addBaseProperties = (item) => {
    const _item = Object.assign({}, item);
    _item._isArchivedIcon = buildIconAsString({
        iconClass: item.isArchived ? 'fad fa-archive' : 'fad fa-box-open',
        title: item.isArchived ? 'Archived' : 'Not archived',
        color: item.isArchived ? 'error--text' : 'grey--text'
    });
    _item._asBuiltNumber = asBuiltNumberDisplay(item.asBuiltNumber ?? item.itemInstance?.asBuiltNumber);
    _item._barcode = item.barcode ?? item.itemInstance?.barcode ?? '-';
    _item._location = item.status?.isOnOrbit.value ? 'On Orbit' : 'On Ground';
    _item._locationIcon = buildIconAsString({
        iconClass: CosmicIcons[_item._location.toUpperCase().replace(' ', '_')],
        title: _item._location,
        color: _item._location === 'On Orbit' ? 'success--text' : 'grey--text'
    });
    _item._serial = serialNumberDisplay(item.serialNumber ?? item.itemInstance?.serialNumber);
    _item._size = sizeDisplay(item.size || item.attributes?.emuSize);
    _item._subType = HardwareTypeDisplay.get(item.subType ?? item.itemInstance?.subType) || '-';
    _item._evaReadiness = EvaReadinessDisplay.get(item.status?.evaReadiness?.value) || '-';
    _item._evaReadinessIcon = buildEvaReadinessIconAsString(item.status?.evaReadiness?.value, 'right');
    return _item;
};
export const addComments = (item) => {
    return commentsToHtml(item);
};
export const addComputedIncrements = (item) => {
    const _item = Object.assign({}, item);
    if (!_item.computedIncrements || _item.computedIncrements.length === 0) {
        _item._increments = buildChipAsString({
            chipIconClass: CosmicIcons[EntityType.EVENT],
            chipText: 'Does not fall within any known Increment.',
            color: EntityType.EVENT,
            iconColor: EntityType.EVENT
        });
        return _item;
    }
    const html = (_item.computedIncrements || [])
        .map((increment) => {
        if (!increment) {
            return '';
        }
        const html = `<li class="my-2">
        ${buildChipAsString({
            chipIconClass: CosmicIcons[EntityType.EVENT],
            chipText: sanitize(increment.name) || '-',
            color: EntityType.EVENT,
            iconColor: EntityType.EVENT
        })}
      </li>`;
        return html;
    })
        .join('');
    _item._increments = `<ul class="pa-0 list-style-none">${html}</ul>`;
    return _item;
};
export const addCycles = (item) => {
    let _cycles = numberDisplay(item.status?.cycles?.value);
    _cycles = alertableStatusDisplay(item.status?.cycles, _cycles);
    const _item = Object.assign({}, item, {
        _cycles
    });
    return _item;
};
export const addDateDisplays = (item) => {
    const _item = Object.assign({}, item);
    Object.entries(item).forEach(([propertyName, propertyValue]) => {
        if (propertyName.endsWith('Date') && !propertyName.startsWith('_')) {
            _item[`_${propertyName}`] = dateDisplay(propertyValue);
        }
    });
    return _item;
};
export const addDaysGreaterThanFiftyPercentSoc = (item) => {
    // get the raw value
    let _daysGreaterThanFiftyPercentSoc = numberDisplay(item.status?.daysOver50PercentStateOfCharge?.value, 0);
    // human-friendly "days" display (Ex: 35d)
    _daysGreaterThanFiftyPercentSoc = `${_daysGreaterThanFiftyPercentSoc || '-'}${_daysGreaterThanFiftyPercentSoc ? 'd' : ''}`;
    // if alertable - adds title tooltip and colorization
    _daysGreaterThanFiftyPercentSoc = alertableStatusDisplay(item.status?.daysOver50PercentStateOfCharge, _daysGreaterThanFiftyPercentSoc);
    const _item = Object.assign({}, item, {
        _daysGreaterThanFiftyPercentSoc
    });
    return _item;
};
export const addIodinateDueDate = (item) => {
    // get the appropriate status prop
    const status = item.status?.iodinateDueDate || item.status?.iodinationDueDate || null;
    if (!status) {
        return Object.assign({}, item, {
            _iodinateDueDate: '-'
        });
    }
    // get the raw value
    let _iodinateDueDate = item.status?.iodinateDueDate?.value || item.status?.iodinationDueDate?.value;
    // human-friendly date
    _iodinateDueDate = dateDisplay(_iodinateDueDate);
    // make it alertable
    _iodinateDueDate = alertableStatusDisplay(status, _iodinateDueDate);
    const _item = Object.assign({}, item, {
        _iodinateDueDate
    });
    return _item;
};
export const addInstalledOnIcon = (item) => {
    const status = item.status;
    const isInstalled = Boolean(item.parentItemInstance);
    const altText = status.isInstalled?.description || '';
    const parentId = item.parentItemInstance?.id;
    const parentSubType = item.parentItemInstance?.subType;
    const parentSubTypeDisplay = HardwareTypeDisplay.get(item.parentItemInstance?.subType);
    const _item = Object.assign({}, item, {
        _isInstalledIcon: isInstalled
            ? buildIconWithLabelAsString({
                iconClass: isInstalled ? CosmicIcons['circle_check'] : 'fad fa-ban',
                label: item.parentItemInstance?.serialNumber
                    ? `<a title="Navigate to ${parentSubTypeDisplay}" href="/hardware/${parentSubType}/manage/${parentId}">${parentSubTypeDisplay} ${item.parentItemInstance?.serialNumber}</a>`
                    : '',
                title: altText,
                labelAlignment: 'right',
                color: isInstalled ? 'success--text' : 'error--text'
            })
            : buildNotOkIcon(altText),
        _isInstalledText: isInstalled ? 'Installed' : 'Not installed',
        _installedSerialNumber: item.parentItemInstance?.serialNumber ?? '-'
    });
    return _item;
};
export const addInstalledChildren = (item) => {
    const hasInstalledChildren = Boolean(item.childItemInstances?.length);
    if (!hasInstalledChildren) {
        return item;
    }
    const installedChildrenHtml = stringListDisplay(item.childItemInstances?.map((childItem) => {
        const childSubTypeDisplay = HardwareTypeDisplay.get(childItem?.subType);
        return `<a title="Navigate to ${childSubTypeDisplay}" href="/hardware/${childItem?.subType}/manage/${childItem?.id}">
          ${childSubTypeDisplay} ${childItem?.serialNumber}
        </a>`;
    }));
    const _item = Object.assign({}, item, {
        _installedChildren: installedChildrenHtml
    });
    return _item;
};
export const addGenericStatus = (item) => {
    const _item = Object.assign({}, item);
    const status = _item.status?.status?.value;
    const statusTitle = _item.status?.status?.description;
    const iconColorFromStatus = status === 'DISCHARGED' ? 'error' : status === 'NOT_CHARGED' ? 'warning' : 'success';
    _item._status = status;
    _item._statusText = statusTitle;
    _item._statusIcon = buildIconAsString({
        iconClass: CosmicIcons[status],
        title: `${statusTitle || ''} ${statusTitle ? '- ' : ''}${status}`,
        color: iconColorFromStatus
    });
    return _item;
};
export const addGenericState = (item) => {
    const _item = Object.assign({}, item);
    const state = _item.status?.batteryState;
    const stateTitle = state?.description || '';
    const iconColorFromState = getColorFromBatteryState(state?.value);
    _item._state = state?.value;
    _item._stateIcon = buildIconAsString({
        iconClass: state?.value ? CosmicIcons[state.value] : CosmicIcons['help'],
        title: `${stateTitle || ''} ${stateTitle ? '- ' : ''}${state?.value || ''}`,
        color: iconColorFromState
    });
    return _item;
};
// if you use this as a transform, don't also use addComments
export const addFemurComments = (item) => {
    return commentsToHtml(item, CommentType.FEMU_R_003);
};
export const addEvaEvents = (item) => {
    const _item = Object.assign({}, item);
    _item._nextPlannedEva = dateDisplay(item.status?.nextEvaEvent?.value?.startDate);
    _item._firstEvaInSeries = item.status?.firstEvaEvent?.value?.startDate
        ? `${item.status?.firstEvaEvent?.value?.name} <br/> ${dateDisplay(item.status?.firstEvaEvent?.value?.startDate)}`
        : '-';
    _item._lastEvaInSeries = item.status?.lastEvaEvent?.value?.startDate
        ? `${item.status?.lastEvaEvent?.value?.name} <br/> ${dateDisplay(item.status?.lastEvaEvent?.value?.startDate)}`
        : '-';
    return _item;
};
export const addItemInstances = (item) => {
    const _item = Object.assign({}, item);
    const html = (_item.itemInstances || [])
        .map((itemInstance) => {
        if (!itemInstance) {
            return '';
        }
        return buildItemInstanceChip(itemInstance);
    })
        .join('');
    _item._itemInstances = _item.itemInstances?.length ? html : '-';
    return _item;
};
export const addItemInstancesUpDown = (item) => {
    const _item = Object.assign({}, item);
    // ItemInstances coming down
    const downItemInstances = (_item.downItemInstances || []).sort(sortObjectBy('subType'));
    // ItemInstances going up
    const upItemInstances = (_item.upItemInstances || []).sort(sortObjectBy('subType'));
    _item._downItemInstances = downItemInstances?.map(buildItemInstanceChip).join('') || '-';
    _item._upItemInstances = upItemInstances?.map(buildItemInstanceChip).join('') || '-';
    return _item;
};
export const addLastDischargeAutocycle = (item) => {
    let _lastDischargeDate = dateDisplay(item.status?.lastDischargeDate?.value);
    _lastDischargeDate = alertableStatusDisplay(item.status?.lastDischargeDate, _lastDischargeDate);
    const _item = Object.assign({}, item, {
        _lastDischargeAutocycle: _lastDischargeDate
    });
    return _item;
};
export const addLastUpdated = (item) => {
    const _item = Object.assign({}, item);
    _item._lastUpdateDate = item.modifiedDate ? dateDisplay(item.modifiedDate) : dateDisplay(item.createdDate);
    _item.lastUpdateDate = item.modifiedDate ? item.modifiedDate : item.createdDate;
    _item._lastUpdatedBy = item.modifiedBy ? item.modifiedBy : item.createdBy;
    return _item;
};
export const addLaunchReturn = (item) => {
    const launchEvent = item.status?.launchEvent.value || null;
    const launchStartDate = launchEvent ? launchEvent.startDate : '';
    const launchName = launchEvent ? sanitize(launchEvent.name) : '';
    const returnEvent = item.status?.returnEvent.value || null;
    const returnStartDate = returnEvent ? returnEvent.startDate : '';
    const returnName = returnEvent ? sanitize(returnEvent.name) : '';
    const _item = Object.assign({}, item, {
        _launchEventTitle: launchEvent ? `${launchName}<br/> on ${dateDisplay(launchStartDate)}` : '-',
        _returnEventTitle: returnEvent ? `${returnName}<br/> on ${dateDisplay(returnStartDate)}` : '-'
    });
    return _item;
};
export const addNextActivityResetDueDate = (item) => {
    const _item = Object.assign({}, item);
    let _nextActivityResetDueDate = dateDisplay(item.status?.activityResetDueDate.value);
    _nextActivityResetDueDate = alertableStatusDisplay(item.status?.activityResetDueDate, _nextActivityResetDueDate);
    _item._nextActivityResetDueDate = _nextActivityResetDueDate;
    return _item;
};
export const addSevenYearExpiry = (item) => {
    let _sevenYearExpiry = dateDisplay(item.status?.expirationDate?.value);
    _sevenYearExpiry = alertableStatusDisplay(item.status?.expirationDate, _sevenYearExpiry);
    const _item = Object.assign({}, item, {
        _sevenYearExpiry
    });
    return _item;
};
export const addNineYearExpiry = (item) => {
    let _nineYearExpiry = dateDisplay(item.status?.expirationDate?.value);
    _nineYearExpiry = alertableStatusDisplay(item.status?.expirationDate, _nineYearExpiry);
    const _item = Object.assign({}, item, {
        _nineYearExpiry
    });
    return _item;
};
export const addStatusDateDisplays = (item) => {
    const _item = Object.assign({}, item);
    if (item.status) {
        Object.entries(item.status)
            .filter(([propertyName, propertyValue]) => propertyName.endsWith('Date'))
            .forEach(([propertyName, propertyValue]) => {
            _item[`_${propertyName}`] = alertableStatusDisplay(propertyValue, dateDisplay(propertyValue.value));
        });
    }
    return _item;
};
export const addUsesAvailable = (item) => {
    let _useCount = numberDisplay(item.status?.useCount?.value);
    _useCount = alertableStatusDisplay(item.status?.useCount, _useCount);
    let _usesAvailable = numberDisplay(item.status?.useCount?.requirementRemaining);
    _usesAvailable = alertableStatusDisplay(item.status?.useCount, _usesAvailable);
    const _item = Object.assign({}, item, {
        _useCount,
        _usesAvailable
    });
    return _item;
};
