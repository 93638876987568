import Vue from 'vue';
import '~/components';
import $store from '~/store';
import App from './App.vue';
import { DatePlugin, DisplayPlugin, LodashPlugin, SafeHtml, Vuetify, VuetifyTheme, WebPlugin } from './plugins';
import { axiosInstance } from './plugins/axios';
import router from './router';
import store from './store';
import { ActionTypes, UserModuleName } from './store/user';
import { stripHtmlFromString } from './utils';
Vue.config.productionTip = false;
$store
    .dispatch(`${UserModuleName}/${ActionTypes.fetchUser}`)
    .then(() => {
    // Plug-ins
    Vue.use(DatePlugin);
    Vue.use(DisplayPlugin);
    Vue.use(LodashPlugin);
    Vue.use(SafeHtml);
    Vue.use(Vuetify, VuetifyTheme);
    Vue.use(WebPlugin);
    const redirectOn401 = (error) => {
        if (error?.response?.status === 401) {
            window.location.href = '/api/oauth/authorize';
        }
        throw error;
    };
    const redirectOn403 = (error) => {
        const { response, request } = error;
        const userRequestPath = '/auth/current-user';
        const isUserRequest = request.responseURL.includes(userRequestPath);
        if (response?.status === 403 && !isUserRequest) {
            router.push(`/unauthorized`);
        }
        throw error;
    };
    axiosInstance.interceptors.response.use((res) => res, redirectOn401);
    axiosInstance.interceptors.response.use((res) => res, redirectOn403);
    Vue.prototype.$http = axiosInstance;
    new Vue({
        router,
        store,
        render: (h) => h(App),
        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    document.title = stripHtmlFromString(to.meta.name) || 'COSMIC - On Orbit Tracker';
                }
            }
        }
    }).$mount('#app');
})
    .catch((error) => {
    if (error?.response?.status === 401) {
        window.location.href = '/api/oauth/authorize';
    }
    throw error;
});
// register service worker
if ('serviceWorker' in navigator) {
    console.log('Registering service worker...');
    navigator.serviceWorker.register('/service-worker.js').then(() => {
        console.log('Service worker registered.');
    });
}
