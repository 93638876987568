import { IeuLocationTypeDisplay } from '~/types';
import { dateDisplay } from '..';
import { FEMUR_COMMENTS_COL, NEXT_PLANNED_EVA_COL, POST_EVA_NLT_COL, POST_SCRUB_IODINATE_DUE_COL, POST_SCRUB_IODINATE_LAST_ACTUAL_COL, PRE_EVA_SCRUB_NET_COL, SERIAL_COL, STANDARD_COLS, WATER_CIRCULATION_DUE_DATE_COL } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addEvaEvents, addFemurComments, addIodinateDueDate, addStatusDateDisplays } from '../mappers';
export const UIA_TABLE_HEADERS = [...STANDARD_COLS];
export const FEMUR_UIA_TABLE_HEADERS = [
    SERIAL_COL,
    NEXT_PLANNED_EVA_COL,
    PRE_EVA_SCRUB_NET_COL,
    POST_EVA_NLT_COL,
    POST_SCRUB_IODINATE_LAST_ACTUAL_COL,
    POST_SCRUB_IODINATE_DUE_COL,
    WATER_CIRCULATION_DUE_DATE_COL,
    FEMUR_COMMENTS_COL
];
export const transformUia = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addEvaEvents)
        .map(addIodinateDueDate)
        .map(addFemurComments)
        .map(addStatusDateDisplays)
        .map((item) => {
        const attributes = item.attributes;
        const status = item.status;
        let _lastActualPostScrubDate = dateDisplay(status.lastIodinateDate?.value);
        _lastActualPostScrubDate = alertableStatusDisplay(status.lastIodinateDate, _lastActualPostScrubDate);
        let _postEVANLTDate = dateDisplay(status?.postEvaScrubDueDate?.value);
        _postEVANLTDate = alertableStatusDisplay(status?.postEvaScrubDueDate, _postEVANLTDate);
        let _preEvaScrubNetDate = dateDisplay(status?.preEvaScrubDueDate?.value);
        _preEvaScrubNetDate = alertableStatusDisplay(status?.preEvaScrubDueDate, _preEvaScrubNetDate);
        let _scrubDate = dateDisplay(status.lastScrubDate?.value);
        _scrubDate = alertableStatusDisplay(status.lastScrubDate, _scrubDate);
        let _waterCirculationDueDate = dateDisplay(status.scrubDueDate?.value);
        _waterCirculationDueDate = alertableStatusDisplay(status.scrubDueDate, _waterCirculationDueDate);
        const _item = Object.assign({}, item, {
            _lastActualPostScrubDate,
            _ieuLocation: attributes.ieuLocation ? IeuLocationTypeDisplay.get(attributes.ieuLocation) : '-',
            _postEVANLTDate,
            _preEvaScrubNetDate,
            _scrubDate,
            _waterCirculationDueDate
        });
        return _item;
    });
};
export const transformUiaStatusPropertiesForAhd = (keys) => {
    const statusKeys = keys;
    return statusKeys.map((key) => {
        // Use "Water Circulation" instead of "Scrub" for IEU/SCU
        return key === 'scrubDueDate' ? 'waterCirculationDueDate' : key;
    });
};
