import { AUTOCYCLE_DUE_DATE_COL, CHARGER_SERIAL_COL, CHARGE_LOCATION_COL, CYCLE_COUNT_COL, DAYS_GREATER_THAN_FIFTY_PERCENT_SOC_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, GENERIC_STATE_ICON_COL, LAST_DISCHARGE_AUTOCYCLE_COL, NINE_YEAR_EXPIRY_COL, SERIAL_COL, STANDARD_COLS, VOLTS_COL } from '../genericTableColumns';
import { addAttributeDateDisplays, addAutocycleDueDate, addBaseProperties, addCycles, addDaysGreaterThanFiftyPercentSoc, addFemurComments, addGenericState, addLastDischargeAutocycle, addLaunchReturn, addNineYearExpiry, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const LREBA_TABLE_HEADERS = [
    ...STANDARD_COLS,
    GENERIC_STATE_ICON_COL,
    DAYS_GREATER_THAN_FIFTY_PERCENT_SOC_COL,
    LAST_DISCHARGE_AUTOCYCLE_COL,
    AUTOCYCLE_DUE_DATE_COL,
    NINE_YEAR_EXPIRY_COL
];
export const FEMUR_LREBA_TABLE_HEADERS = [
    SERIAL_COL,
    LAST_DISCHARGE_AUTOCYCLE_COL,
    NINE_YEAR_EXPIRY_COL,
    CYCLE_COUNT_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_LREBA_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    VOLTS_COL,
    CHARGE_LOCATION_COL,
    CHARGER_SERIAL_COL
];
export const transformLreba = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addAutocycleDueDate)
        .map(addBaseProperties)
        .map(addGenericState)
        .map(addDaysGreaterThanFiftyPercentSoc)
        .map(addFemurComments)
        .map(addLastDischargeAutocycle)
        .map(addLaunchReturn)
        .map(addNineYearExpiry)
        .map(addStatusDateDisplays)
        .map(addCycles);
};
