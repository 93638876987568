import { alertableStatusDisplay, dateDisplay } from '..';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, FEMUR_LAST_ACTUAL_POWER_CYCLE_COL, FEMUR_POWER_CYCLE_DUE_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const ACTIVITY_EHIP_LIGHT_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const EHIP_LIGHT_TABLE_HEADERS = [...STANDARD_COLS, FEMUR_POWER_CYCLE_DUE_COL];
export const FEMUR_EHIP_LIGHT_TABLE_HEADERS = [
    SERIAL_COL,
    FEMUR_POWER_CYCLE_DUE_COL,
    FEMUR_LAST_ACTUAL_POWER_CYCLE_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const transformEhipLight = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item?.status;
        let _powerCycleDueDate = dateDisplay(status?.powerCycleDueDate?.value);
        _powerCycleDueDate = alertableStatusDisplay(status?.powerCycleDueDate, _powerCycleDueDate);
        let _powerCycleLastActualDate = dateDisplay(status?.lastPowerCycleDate?.value);
        _powerCycleLastActualDate = alertableStatusDisplay(status?.lastPowerCycleDate, _powerCycleLastActualDate);
        const _item = Object.assign({}, item, {
            _powerCycleDueDate,
            _powerCycleLastActualDate
        });
        return _item;
    });
};
