var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BaseActivity, BaseItemInstance, Event } from '~/types';
let ButtonReadOnly = class ButtonReadOnly extends Vue {
    itemInstance;
    activity;
    event;
    get icon() {
        return 'fad fa-link';
    }
    get activityId() {
        return this.activity?.id;
    }
    get activitySubType() {
        return this.activity?.subType;
    }
    get eventId() {
        return this.event?.id;
    }
    get eventSubType() {
        return this.event?.subType;
    }
    get itemInstanceId() {
        return this.itemInstance?.id;
    }
    get itemInstanceSubType() {
        return this.itemInstance?.subType;
    }
    get readOnlyLink() {
        if (this.itemInstance) {
            return `/reporting/item_instances/${this.itemInstanceId}?subType=${this.itemInstanceSubType}`;
        }
        if (this.activity) {
            return `/reporting/activities/${this.activityId}?subType=${this.activitySubType}`;
        }
        if (this.event) {
            return `/reporting/events/${this.eventId}?subType=${this.eventSubType}`;
        }
    }
    navigateToReadOnlyView() {
        if (this.readOnlyLink) {
            this.$router.push(this.readOnlyLink);
        }
        else {
            this.$notification.add({ text: 'Something went wrong. Please refresh.', type: 'error' });
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof BaseItemInstance !== "undefined" && BaseItemInstance) === "function" ? _a : Object)
], ButtonReadOnly.prototype, "itemInstance", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof BaseActivity !== "undefined" && BaseActivity) === "function" ? _b : Object)
], ButtonReadOnly.prototype, "activity", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_c = typeof Event !== "undefined" && Event) === "function" ? _c : Object)
], ButtonReadOnly.prototype, "event", void 0);
ButtonReadOnly = __decorate([
    Component
], ButtonReadOnly);
export default ButtonReadOnly;
