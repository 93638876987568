export default new Map([
    ['PreEvaScrubDue', 'Pre-EVA<br/>Scrub due after'],
    ['AnnualChangeOut', 'Annual ChangeOut'],
    ['Charge', ' Charge'],
    ['CheckOut', ' CheckOut'],
    ['CycleNumber', 'Initial Cycles'],
    ['Date', ''],
    ['Dcm', 'DCM'],
    ['Due', 'due'],
    ['due', ' due'],
    ['Dump', 'Dump'],
    ['EcwsId', 'eCWS ID'],
    ['EmuSN', 'EMU Serial'],
    ['EstimatedAutocycle', 'Estimated Autocycle, Recharge, and Checkout'],
    ['Fill', ' Fill'],
    ['First', 'First '],
    ['full', 'Full '],
    ['Full', 'Full '],
    ['GN2Check', 'GN2 Check'],
    ['GreaseCirculate', 'Grease / Circulate'],
    ['GroundCalibration', 'Ground Calibration'],
    ['GroundCycles', 'Ground Cycles'],
    ['hut', 'HUT '],
    ['Hut', 'HUT '],
    ['IeuLocation', 'IEU Location'],
    ['Last', 'Last '],
    ['Lcvg', 'LCVG'],
    ['MetoxRegeneration', 'METOX Regeneration'],
    ['ORingChange', 'O-Ring Change'],
    ['ORingExpiration', 'O-Ring expiration'],
    ['partial', 'Partial '],
    ['Partial', 'Partial '],
    ['Pia', 'PIA'],
    ['PostEva', 'Post-EVA<br/>'],
    ['PowerCycle', 'Power Cycle'],
    ['Pwr', 'PWR'],
    ['RemoveReplace', ' R/R '],
    ['Reset', ' Reset'],
    ['SaferTm', 'SAFER TM'],
    ['Sop', 'SOP'],
    ['SserAltId', 'SSER Alt ID'],
    ['SserPriId', 'SSER Pri ID'],
    ['SserSN', 'SSER Serial'],
    ['Start', ' start'],
    ['TakDataCollection', 'TAK Data Collection'],
    ['TakDataApproval', 'TAK Data Approval'],
    ['UseCount', 'Uses'],
    ['UsesAvailable', 'Uses Available'],
    ['water', 'H<sub>2</sub>O '],
    ['Water', 'H<sub>2</sub>O '],
    ['Wlvta', 'WLVTA'],
    ['wlvta', 'WLVTA']
]);
