import { HardwareType } from '~/types/schemas';
export const AUTH_PATH = '/api/oauth/authorize';
export const BUCKET_PATH = 'https://public.cosmic.appdat.jsc.nasa.gov/assets';
export const DB_DEFAULT = 'DEFAULT';
export const DEFAULT_DASH = '-';
export const GENERAL = 'GENERAL';
export const LOC_DB_DEFAULT = GENERAL;
export const LOGOUT_PATH = '/api/oauth/logout';
export * from './activities';
export * from './comments';
export * from './ehipLight';
export * from './emu';
export * from './emuBattery';
export * from './emuBatteryLlb';
export * from './emuBiocideFilter';
export * from './emuFeedwaterSupplyFilter';
export * from './emuIonFilter';
export * from './emuMicronFilter';
export * from './emuScrubAssembly';
export * from './emuWaterProcessingJumper';
export * from './events';
export * from './fps';
export * from './hlBattery';
export * from './hutOru';
export * from './hutOruScrubber';
export * from './ieuScu';
export * from './ieuScuUiaEmu';
export * from './itemInstances';
export * from './lcvg';
export * from './legacyFpu';
export * from './libCharger';
export * from './lpgtBattery';
export * from './lreba';
export * from './metox';
export * from './metoxSimulator';
export * from './pendingMaintenance';
export * from './pgt';
export * from './pgtBattery';
export * from './reba';
export * from './safer';
export * from './saferLpgtBattery';
export * from './safetyTether55';
export * from './safetyTether85';
export * from './scof';
export * from './tetherExtensionDringExtender';
export * from './uia';
export * from './uiaBiocideFilter';
export * from './waistTether';
export const READ_ONLY_HARDWARE_TYPES = [
    HardwareType.EMU_BATTERY,
    HardwareType.EMU_ION_FILTER,
    HardwareType.EMU_MICRON_FILTER,
    HardwareType.HL_BATTERY,
    HardwareType.PGT_BATTERY,
    HardwareType.REBA
];
export const ERROR_MESSAGE_MAPPER = (error, backupErrorText) => {
    /*
     * This error message mapper checks for a GraphQL error string
     * and returns a human readable message appropriate to display
     * to the end user.
     */
    let errorMessage = '';
    if (error.includes('template requires authority membership')) {
        const groupCodeSplit = error.split('group_code=');
        const groupCode = groupCodeSplit[1].replace(/[^a-zA-Z_ ]/g, '');
        return (errorMessage = `Error: You are not a part of ${groupCode}, cannot perform action.`);
    }
    if (error.includes('inventory_has_open_change_set')) {
        return (errorMessage = `Error: Inventory has an open Change Set and cannot move.`);
    }
    if (error.includes('item_master_initialization_needs_approval')) {
        return (errorMessage = `Error: Item Master requires Initialization before receiving into inventory.`);
    }
    if (error.includes('needs_inspection')) {
        return (errorMessage = 'Error: Inspection is required.');
    }
    if (error.includes('calibration_requires_type')) {
        return (errorMessage = 'Error: Calibration Type is required.');
    }
    if (error.includes('item master does not exist')) {
        return (errorMessage = `Error: No valid Item Master found matching this AsBuilt.`);
    }
    if (error.includes('bad_update_change_set_hardware')) {
        return (errorMessage = `Existing change sets prevent hardware from being updated at this time.`);
    }
    if (error.includes('locations_uniq_bin_area')) {
        return (errorMessage = `Location already exists. Must be unique by Contract, Bin, Stock, Room, Building.`);
    }
    if (error.includes('update or delete on table "documents" violates foreign key constraint "comments_document_id_fkey"')) {
        return (errorMessage = 'Document cannot be deleted because it has associated comments.');
    }
    if (error.includes('update or delete on table "documents" violates foreign key constraint "external_systems_document_id_fkey"')) {
        return (errorMessage = 'Document cannot be deleted because it has associated links to external systems.');
    }
    if (error.includes('update or delete on table "locations" violates foreign key constraint "inventory_location_id_fkey"')) {
        return (errorMessage = 'Location cannot be deleted because it has, or previously had, inventory.');
    }
    if (error.includes('update or delete on table "locations" violates foreign key constraint "events_location_id_fkey"')) {
        return (errorMessage = 'Location cannot be deleted because it is associated with an event.');
    }
    if (error.includes('error: inventory_installed')) {
        return (errorMessage = 'Unable to make changes, inventory is currently installed.');
    }
    if (error.includes('duplicate key value violates unique constraint "change_set_templates_sub_type_support_organization_code_gro_idx"')) {
        return (errorMessage = 'Cannot modify a Change Set Template with the same Equipment Group.');
    }
    if (error.includes('update or delete on table "activities" violates foreign key constraint "comments_activity_id_fkey"')) {
        return (errorMessage = 'Activity cannot be deleted because it has associated comments.');
    }
    if (error.includes('duplicate key value violates unique constraint')) {
        return (errorMessage = `Error: Item already created, please refresh your browser.`);
    }
    /*
     * These will eventually be handled by a backend `CASCADE_DELETE` but adding this here for posterity until then
     */
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "hardware_list_assemblies_hardware_list_id_fkey" on table "hardware_list_assemblies"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated parts.`);
    }
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "hardware_list_templates_hardware_list_id_fkey" on table "hardware_list_templates"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated templates.`);
    }
    if (error.includes('update or delete on table "hardware_lists" violates foreign key constraint "comments_hardware_list_id_fkey"')) {
        return (errorMessage = `Hardware List cannot be deleted because it has associated comments.`);
    }
    errorMessage = 'An error has occurred on the page, please try again.';
    return backupErrorText || errorMessage;
};
