/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppTable.vue?vue&type=template&id=130ab474&scoped=true"
import script from "./AppTable.ts?vue&type=script&lang=ts&external"
export * from "./AppTable.ts?vue&type=script&lang=ts&external"
import style0 from "./AppTable.vue?vue&type=style&index=0&id=130ab474&prod&lang=scss&scoped=true"
import style1 from "./AppTable.vue?vue&type=style&index=1&id=130ab474&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130ab474",
  null
  
)

export default component.exports