import { EMU_SERIAL_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS, USES_AVAILABLE_COL } from '../genericTableColumns';
import { addBaseProperties, addFemurComments, addLaunchReturn, addUsesAvailable } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_BIOCIDE_FILTER_TABLE_HEADERS = [...STANDARD_COLS, USES_AVAILABLE_COL];
export const FEMUR_EMU_BIOCIDE_FILTER_TABLE_HEADERS = [
    SERIAL_COL,
    USES_AVAILABLE_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_EMU_BIOCIDE_FILTER_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS, EMU_SERIAL_COL];
export const transformEmuBiocideFilter = (items) => {
    return items.map(addBaseProperties).map(addUsesAvailable).map(addLaunchReturn).map(addFemurComments);
};
export const transformEmuBiocideFilterStatusPropertiesForAhd = (keys) => {
    const statusKeys = keys;
    return statusKeys.map((key) => {
        // Use "usesAvailable" instead of "useCount" for EmuBiocideFilter
        return key === 'useCount' ? 'usesAvailable' : key;
    });
};
