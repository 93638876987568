import { GENERIC_STATUS_ICON_COL, STANDARD_COLS } from '../genericTableColumns';
import { addBaseProperties, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const WAIST_TETHER_TABLE_HEADERS = [...STANDARD_COLS];
export const ACTIVITY_WAIST_TETHER_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    GENERIC_STATUS_ICON_COL
];
export const transformWaistTether = (items) => {
    return items.map(addBaseProperties).map(addLaunchReturn);
};
