import { GENERIC_STATUS_ICON_COL, STANDARD_COLS } from '../genericTableColumns';
import { addBaseProperties, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const transformSafetyTether55 = (items) => {
    return items.map(addBaseProperties).map(addLaunchReturn);
};
// ex status => "No go for EVA"|"Requires Inspection"|"Go for EVA"
export const SAFETYTETHER55_TABLE_HEADERS = [...STANDARD_COLS];
export const ACTIVITY_SAFETY_TETHER_55_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    GENERIC_STATUS_ICON_COL
];
