import { ActivityType, BatteryChargeLocation, BatteryChargeLocationChannel, FullPartialStatus, ItemScu, LithiumBatteryChannel, LithiumBatteryChargeLocation, PgtRole } from './schemas';
export const ActivityTypeDisplay = new Map([
    [ActivityType.ANNUAL_CHANGE_OUT, 'Annual ChangeOut'],
    [ActivityType.ANNUAL_CHECK_OUT, 'Annual CheckOut'],
    [ActivityType.AUTOCYCLE, 'Autocycle'],
    [ActivityType.BATTERY_MAINTENANCE, 'Battery Maint.'],
    [ActivityType.CALIBRATION, 'Calibration'],
    [ActivityType.CHARGE, 'Charge'],
    [ActivityType.CWC_DUMP, 'CWC Dump'],
    [ActivityType.DCM_MWC_FLOW_TEST, 'DCM MWC Flow Test'],
    [ActivityType.DISCHARGE, 'Discharge'],
    [ActivityType.EMU_WATER_SAMPLE, 'EMU Annual Water Sample'],
    [ActivityType.EMU_CHECK_OUT, 'EMU CheckOut'],
    [ActivityType.EMU_H2O_DUMP_FILL, 'EMU H<sub>2</sub>O Dump/Fill'],
    [ActivityType.EMU_H2O_RECHARGE, 'EMU H<sub>2</sub>O Recharge'],
    [ActivityType.EVA, 'EVA'],
    [ActivityType.GN2_CHECK, 'GN2 Check'],
    [ActivityType.GREASE_CIRCULATE, 'Grease / Circulate'],
    [ActivityType.HUT_WLVTA_REMOVE_REPLACE, 'HUT WLVTA R&R'],
    [ActivityType.INSPECTION, 'Inspection'],
    [ActivityType.IODINATE_LOOP_SCRUB, 'Loop Scrub w/Iodination'],
    [ActivityType.ITEM_146_CYCLE, 'Item 146 Cycle'],
    [ActivityType.LCVG_FILL, 'LCVG Fill'],
    [ActivityType.LOOP_SCRUB, 'Loop Scrub'],
    [ActivityType.METOX_REGENERATION, 'METOX Regen'],
    [ActivityType.O2_POPPET_VERIFICATION, 'O<sub>2</sub> Poppet Verification'],
    [ActivityType.OPS_10_2, '10.2 OPS'],
    [ActivityType.OTHER, 'Other'],
    [ActivityType.PWR_FILL, 'PWR Fill'],
    [ActivityType.PWR_VENT, 'PWR Vent'],
    [ActivityType.SAFER_CHECK_OUT, 'SAFER CheckOut'],
    [ActivityType.SAFER_TM_CHECK_OUT, 'SAFER TM CheckOut'],
    [ActivityType.SOP_CHECK_OUT, 'SOP CheckOut'],
    [ActivityType.SSM_EXTENSION_APPROVAL, 'SSM Extension Approval'],
    [ActivityType.SUBLIMATOR_LEAK_TEST, 'Sublimator Leak Test'],
    [ActivityType.TAK_DATA_APPROVAL, 'TAK Data Approval'],
    [ActivityType.TAK_DATA_COLLECTION, 'TAK Data Collection']
]);
export const FullPartialStatusDisplay = new Map([
    [FullPartialStatus.FULL, 'Full'],
    [FullPartialStatus.N_A, 'N/A'],
    [FullPartialStatus.PARTIAL, 'Partial']
]);
export const ItemScuDisplay = new Map([
    [ItemScu.NONE, 'None'],
    [ItemScu.N_A, 'N/A'],
    [ItemScu.SCU_1, 'SCU 1'],
    [ItemScu.SCU_2, 'SCU 2']
]);
export const LithiumBatteryChargeLocationDisplay = new Map([
    [LithiumBatteryChargeLocation.VOLT_28_PSA, '28 Volt-PSA'],
    [LithiumBatteryChargeLocation.VOLT_120, '120 Volt'],
    [LithiumBatteryChargeLocation.EBOT_1, 'EBOT 1'],
    [LithiumBatteryChargeLocation.EBOT_2, 'EBOT 2'],
    [LithiumBatteryChargeLocation.EBOT_3, 'EBOT 3'],
    [LithiumBatteryChargeLocation.OTHER, 'Other']
]);
export const BatteryChargeLocationDisplay = new Map([
    [BatteryChargeLocation.BC1, 'BC1'],
    [BatteryChargeLocation.BC2, 'BC2'],
    [BatteryChargeLocation.BC3, 'BC3'],
    [BatteryChargeLocation.BC4, 'BC4'],
    [BatteryChargeLocation.OTHER, 'Other']
]);
export const BatteryChargeLocationChannelDisplay = new Map([
    [BatteryChargeLocationChannel.CH1, 'CH1'],
    [BatteryChargeLocationChannel.CH2, 'CH2'],
    [BatteryChargeLocationChannel.CH3, 'CH3'],
    [BatteryChargeLocationChannel.CH4, 'CH4'],
    [BatteryChargeLocationChannel.CH5, 'CH5'],
    [BatteryChargeLocationChannel.CH6, 'CH6']
]);
export const LithiumBatteryChargeLocationChannelDisplay = new Map([
    [LithiumBatteryChannel.CHANNEL_A, 'A'],
    [LithiumBatteryChannel.CHANNEL_B, 'B'],
    [LithiumBatteryChannel.CHANNEL_1, '1'],
    [LithiumBatteryChannel.CHANNEL_2, '2'],
    [LithiumBatteryChannel.CHANNEL_3, '3'],
    [LithiumBatteryChannel.CHANNEL_4, '4'],
    [LithiumBatteryChannel.CHANNEL_5, '5']
]);
export const PgtRoleDisplay = new Map([
    [PgtRole.AIRLOCK, 'Airlock'],
    [PgtRole.EV1, 'EV1'],
    [PgtRole.EV2, 'EV2']
]);
