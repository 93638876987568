import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, FILTER_CHECKOUT_DUE_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const METOX_SIMULATOR_TABLE_HEADERS = [...STANDARD_COLS, FILTER_CHECKOUT_DUE_COL];
export const FEMUR_METOX_SIMULATOR_TABLE_HEADERS = [
    SERIAL_COL,
    FILTER_CHECKOUT_DUE_COL,
    {
        text: 'Filter CheckOut<br/>Last Actual<br/><small>(every 1,095d)</small>',
        value: '_lastActualMaintenanceDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_METOX_SIMULATOR_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformMetoxSimulator = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item.status;
        const annualChangeOutDueDate = status?.annualChangeOutDueDate?.value;
        const _item = Object.assign({}, item, {
            _filterCheckOutDueDate: alertableStatusDisplay(status?.annualChangeOutDueDate, dateDisplay(annualChangeOutDueDate)),
            _lastActualMaintenanceDate: alertableStatusDisplay(status?.lastAnnualChangeOutDate, dateDisplay(status?.lastAnnualChangeOutDate?.value))
        });
        return _item;
    });
};
