import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, PIA_DATE_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
const PPRV_COL = {
    text: 'PPRV Cycle due<br/><small>(every 377d)</small>',
    value: '_pprvCycleDueDate',
    sortable: true,
    noEscape: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const SCOF_TABLE_HEADERS = [...STANDARD_COLS, PIA_DATE_COL, PPRV_COL];
export const FEMUR_SCOF_TABLE_HEADERS = [
    SERIAL_COL,
    PPRV_COL,
    {
        text: 'PPRV Cycle<br/>Last Actual<br/><small>(every 377d)</small>',
        value: '_lastActualMaintDate',
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_SCOF_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformScof = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item.status;
        let _lastActualMaintDate = dateDisplay(status?.lastAnnualCheckOutDate?.value);
        _lastActualMaintDate = alertableStatusDisplay(status?.lastAnnualCheckOutDate, _lastActualMaintDate);
        let _pprvCycleDueDate = dateDisplay(status?.annualCheckOutDueDate?.value);
        _pprvCycleDueDate = alertableStatusDisplay(status?.annualCheckOutDueDate, _pprvCycleDueDate);
        let _valveCycleDueDate = dateDisplay(status?.annualCheckOutDueDate?.value);
        _valveCycleDueDate = alertableStatusDisplay(status?.annualCheckOutDueDate, _valveCycleDueDate);
        const _item = Object.assign({
            _lastActualMaintDate,
            _pprvCycleDueDate,
            _valveCycleDueDate
        }, item);
        return _item;
    });
};
