import { HardwareType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '~/utils/dates/dateDisplay';
import { COMPLETED_EVAS_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, ITEM_146_EXPIRATION_COL, SERIAL_COL, SIZE_COL, STANDARD_COLS } from '~/utils/genericTableColumns';
import { alertableStatusDisplay } from '~/utils/helpers';
import { addAttributeDateDisplays, addBaseProperties, addEvaEvents, addFemurComments, addInstalledChildren, addLaunchReturn } from '~/utils/mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_TABLE_HEADERS = [
    ...STANDARD_COLS,
    SIZE_COL,
    {
        text: 'PIA expiry',
        value: '_piaExpirationDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    COMPLETED_EVAS_COL,
    {
        text: 'Full H<sub>2</sub>O Dump due',
        value: '_fullWaterDumpDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Partial H<sub>2</sub>O Dump due',
        value: '_partialWaterDumpDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Water Sample due',
        value: '_waterSampleDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Last EVA',
        value: '_lastEvaDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE,
        align: 'right'
    },
    ITEM_146_EXPIRATION_COL,
    {
        text: 'SOP CheckOut<br/>expiration',
        value: '_sopCheckOutDueDate',
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE,
        noWrap: true
    },
    {
        text: 'Iodinate due',
        value: '_iodinateDueDate',
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Installed<br/>HUT ORU',
        value: '_installedHutSerial',
        sortable: true
    }
];
export const FEMUR_EMU_TABLE_HEADERS = [
    SERIAL_COL,
    SIZE_COL,
    {
        text: 'H<sub>2</sub>O Ground Charge',
        value: '_waterChargeDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    COMPLETED_EVAS_COL,
    {
        text: 'Partial Dump & Fill<br/>Last Actual<br/><small>(180d)</small>',
        value: '_lastPartialWaterDumpDate',
        noWrap: true,
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Partial Dump & Fill<br/>due<br/><small>(180d)</small>',
        value: '_partialWaterDumpDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Full Dump & Fill<br/>Last Actual<br/><small>(365d / 14 EVA)</small>',
        value: '_lastFullWaterDumpDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Full Dump & Fill<br/>due<br/><small>(365d / 14 EVA)</small>',
        value: '_fullWaterDumpDueDate',
        noWrap: true,
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Water Sample<br/>due<br/><small>(365d)</small>',
        value: '_waterSampleDueDate',
        noWrap: true,
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    Object.assign({}, ITEM_146_EXPIRATION_COL, {
        text: 'I-146 PPRV Cycle due<br/><small>(Or prior to suit pressurization)</small>'
    }),
    {
        text: 'HUT WLVTA<br/>due<br/><small>(4y)</small>',
        value: '_hutWlvtaRemoveReplaceDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const FEMUR_EMU_COMMENTS_TABLE_HEADERS = [SERIAL_COL, FEMUR_COMMENTS_COL];
export const FEMUR_EMU_EXPORT_TABLE_HEADERS = [
    SERIAL_COL,
    SIZE_COL,
    {
        text: 'H<sub>2</sub>O Ground Charge',
        value: '_waterChargeDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    COMPLETED_EVAS_COL,
    {
        text: 'Partial Dump & Fill<br/>Last Actual<br/><small>(180d)</small>',
        value: '_lastPartialWaterDumpDate',
        noWrap: true,
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Partial Dump & Fill<br/>due<br/><small>(180d)</small>',
        value: '_partialWaterDumpDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Full Dump & Fill<br/>Last Actual<br/><small>(365d / 14 EVA)</small>',
        value: '_lastFullWaterDumpDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Full Dump & Fill<br/>due<br/><small>(365d / 14 EVA)</small>',
        value: '_fullWaterDumpDueDate',
        noWrap: true,
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Water Sample due',
        value: '_waterSampleDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    Object.assign({}, ITEM_146_EXPIRATION_COL, {
        text: 'I-146 PPRV Cycle due<br/><small>(Or prior to suit pressurization)</small>'
    }),
    {
        text: 'HUT WLVTA<br/>due<br/><small>(4y)</small>',
        value: '_hutWlvtaRemoveReplaceDueDate',
        sortable: true,
        noWrap: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_EMU_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        text: 'Fan On > 2h?',
        value: '_fanOnGreaterThanTwoHoursIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    {
        text: 'SOP P',
        value: '_sopPressure',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'SOP<br/>Regulation PSI',
        value: '_sopRegulationPressure',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'CCAA<br/>Temp (°F)',
        value: '_ccaaTemp',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'EMU<br/>Temp (°F)',
        value: '_emuTemp',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'SCU',
        value: '_scuSelection',
        sortable: true
    },
    {
        text: 'H<sub>2</sub>O Dump',
        value: '_waterDump',
        sortable: true
    }
];
export const transformEmu = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addEvaEvents)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map(addInstalledChildren)
        .map((item) => {
        const status = item?.status;
        const attributes = item?.attributes;
        const installedHut = item.childItemInstances?.find((ii) => ii.subType === HardwareType.HUT_ORU);
        const _installedHutSerial = installedHut?.serialNumber ?? '-';
        const piaExpirationDate = status?.expirationDate?.value;
        const _piaExpirationDueDate = dateDisplay(piaExpirationDate);
        // COMPLETED EVAS
        const _completedEvas = status?.totalEvas?.value ?? '-';
        // Last EVA Date
        let _lastEvaDate = status?.lastEvaEvent?.value?.startDate;
        _lastEvaDate = dateDisplay(_lastEvaDate);
        // Item 146 cycle due date
        let _item146ExpirationDate = dateDisplay(status?.item146CycleDueDate?.value);
        _item146ExpirationDate = alertableStatusDisplay(status.item146CycleDueDate, _item146ExpirationDate);
        // last full water dump
        let _lastFullWaterDumpDate = dateDisplay(status.lastFullWaterDumpDate?.value);
        _lastFullWaterDumpDate = alertableStatusDisplay(status.lastFullWaterDumpDate, _lastFullWaterDumpDate);
        // full water dump DUE date
        let _fullWaterDumpDueDate = dateDisplay(status.fullWaterDumpDueDate?.value);
        _fullWaterDumpDueDate = alertableStatusDisplay(status.fullWaterDumpDueDate, _fullWaterDumpDueDate);
        // last partial water dump
        let _lastPartialWaterDumpDate = dateDisplay(status.lastPartialWaterDumpDate?.value);
        _lastPartialWaterDumpDate = alertableStatusDisplay(status.lastPartialWaterDumpDate, _lastPartialWaterDumpDate);
        // partial water dump DUE date
        let _partialWaterDumpDueDate = dateDisplay(status.partialWaterDumpDueDate?.value);
        _partialWaterDumpDueDate = alertableStatusDisplay(status.partialWaterDumpDueDate, _partialWaterDumpDueDate);
        // water sample DUE date
        let _waterSampleDueDate = dateDisplay(status.waterSampleDueDate?.value);
        _waterSampleDueDate = alertableStatusDisplay(status.waterSampleDueDate, _waterSampleDueDate);
        // pre-EVA scrub DUE date
        let _preEvaScrubDueDate = dateDisplay(status.preEvaScrubDueDate?.value);
        _preEvaScrubDueDate = alertableStatusDisplay(status.preEvaScrubDueDate, _preEvaScrubDueDate);
        dateDisplay(status.lastLoopScrubDate?.value);
        // post-EVA scrub DUE date
        let _postEvaScrubDueDate = dateDisplay(status.postEvaScrubDueDate?.value);
        _postEvaScrubDueDate = alertableStatusDisplay(status.postEvaScrubDueDate, _postEvaScrubDueDate);
        // iodinate DUE date
        let _iodinateDueDate = dateDisplay(status.iodinateDueDate?.value);
        _iodinateDueDate = alertableStatusDisplay(status.iodinateDueDate, _iodinateDueDate);
        // Last IODINATE / LOOP SCRUB Date
        let _lastIodinateDate = dateDisplay(status.lastIodinateDate?.value);
        _lastIodinateDate = alertableStatusDisplay(status.lastIodinateDate, _lastIodinateDate);
        // sop check out DUE date
        let _sopCheckOutDueDate = dateDisplay(status.sopCheckOutDueDate?.value);
        _sopCheckOutDueDate = alertableStatusDisplay(status.sopCheckOutDueDate, _sopCheckOutDueDate);
        // wlvta DUE date
        let _hutWlvtaRemoveReplaceDueDate = dateDisplay(status.hutWlvtaRemoveReplaceDueDate?.value);
        _hutWlvtaRemoveReplaceDueDate = alertableStatusDisplay(status.hutWlvtaRemoveReplaceDueDate, _hutWlvtaRemoveReplaceDueDate);
        const _item = Object.assign({}, item, {
            _completedEvas,
            _lastEvaDate,
            _lastFullWaterDumpDate,
            _piaExpirationDueDate,
            _item146ExpirationDate,
            _fullWaterDumpDueDate,
            _waterSampleDueDate,
            _lastPartialWaterDumpDate,
            _lastIodinateDate,
            _preEvaScrubDueDate,
            _postEvaScrubDueDate,
            _iodinateDueDate,
            _partialWaterDumpDueDate,
            _sopCheckOutDueDate,
            _hutWlvtaRemoveReplaceDueDate,
            _installedHutSerial
        });
        return _item;
    });
};
