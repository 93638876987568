/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
export var ActivityType;
(function (ActivityType) {
    ActivityType["ANNUAL_CHANGE_OUT"] = "ANNUAL_CHANGE_OUT";
    ActivityType["ANNUAL_CHECK_OUT"] = "ANNUAL_CHECK_OUT";
    ActivityType["AUTOCYCLE"] = "AUTOCYCLE";
    ActivityType["BATTERY_MAINTENANCE"] = "BATTERY_MAINTENANCE";
    ActivityType["CALIBRATION"] = "CALIBRATION";
    ActivityType["CHARGE"] = "CHARGE";
    ActivityType["CWC_DUMP"] = "CWC_DUMP";
    ActivityType["DCM_MWC_FLOW_TEST"] = "DCM_MWC_FLOW_TEST";
    ActivityType["DISCHARGE"] = "DISCHARGE";
    ActivityType["EMU_CHECK_OUT"] = "EMU_CHECK_OUT";
    ActivityType["EMU_H2O_DUMP_FILL"] = "EMU_H2O_DUMP_FILL";
    ActivityType["EMU_H2O_RECHARGE"] = "EMU_H2O_RECHARGE";
    ActivityType["EVA"] = "EVA";
    ActivityType["GN2_CHECK"] = "GN2_CHECK";
    ActivityType["GREASE_CIRCULATE"] = "GREASE_CIRCULATE";
    ActivityType["HUT_WLVTA_REMOVE_REPLACE"] = "HUT_WLVTA_REMOVE_REPLACE";
    ActivityType["INSPECTION"] = "INSPECTION";
    ActivityType["IODINATE_LOOP_SCRUB"] = "IODINATE_LOOP_SCRUB";
    ActivityType["ITEM_146_CYCLE"] = "ITEM_146_CYCLE";
    ActivityType["LCVG_FILL"] = "LCVG_FILL";
    ActivityType["LOOP_SCRUB"] = "LOOP_SCRUB";
    ActivityType["METOX_REGENERATION"] = "METOX_REGENERATION";
    ActivityType["O2_POPPET_VERIFICATION"] = "O2_POPPET_VERIFICATION";
    ActivityType["OPS_10_2"] = "OPS_10_2";
    ActivityType["OTHER"] = "OTHER";
    ActivityType["PWR_FILL"] = "PWR_FILL";
    ActivityType["PWR_VENT"] = "PWR_VENT";
    ActivityType["SAFER_CHECK_OUT"] = "SAFER_CHECK_OUT";
    ActivityType["SAFER_TM_CHECK_OUT"] = "SAFER_TM_CHECK_OUT";
    ActivityType["SOP_CHECK_OUT"] = "SOP_CHECK_OUT";
    ActivityType["SSM_EXTENSION_APPROVAL"] = "SSM_EXTENSION_APPROVAL";
    ActivityType["SUBLIMATOR_LEAK_TEST"] = "SUBLIMATOR_LEAK_TEST";
    ActivityType["TAK_DATA_APPROVAL"] = "TAK_DATA_APPROVAL";
    ActivityType["TAK_DATA_COLLECTION"] = "TAK_DATA_COLLECTION";
    ActivityType["EMU_WATER_SAMPLE"] = "EMU_WATER_SAMPLE";
})(ActivityType || (ActivityType = {}));
export var AlertType;
(function (AlertType) {
    AlertType["INFO"] = "INFO";
    AlertType["WARNING"] = "WARNING";
    AlertType["ALERT"] = "ALERT";
    AlertType["ERROR"] = "ERROR";
})(AlertType || (AlertType = {}));
export var AuditLogEntityType;
(function (AuditLogEntityType) {
    AuditLogEntityType["ITEM_INSTANCE"] = "ITEM_INSTANCE";
    AuditLogEntityType["ACTIVITY"] = "ACTIVITY";
    AuditLogEntityType["ACTIVITY_TO_ITEM_INSTANCE"] = "ACTIVITY_TO_ITEM_INSTANCE";
    AuditLogEntityType["EVENT"] = "EVENT";
    AuditLogEntityType["EVENT_TO_ITEM_INSTANCE"] = "EVENT_TO_ITEM_INSTANCE";
})(AuditLogEntityType || (AuditLogEntityType = {}));
export var AuditLogType;
(function (AuditLogType) {
    AuditLogType["INSERT"] = "INSERT";
    AuditLogType["UPDATE"] = "UPDATE";
    AuditLogType["DELETE"] = "DELETE";
})(AuditLogType || (AuditLogType = {}));
export var BatteryChargeLocation;
(function (BatteryChargeLocation) {
    BatteryChargeLocation["BC1"] = "BC1";
    BatteryChargeLocation["BC2"] = "BC2";
    BatteryChargeLocation["BC3"] = "BC3";
    BatteryChargeLocation["BC4"] = "BC4";
    BatteryChargeLocation["OTHER"] = "OTHER";
})(BatteryChargeLocation || (BatteryChargeLocation = {}));
export var BatteryChargeLocationChannel;
(function (BatteryChargeLocationChannel) {
    BatteryChargeLocationChannel["CH1"] = "CH1";
    BatteryChargeLocationChannel["CH2"] = "CH2";
    BatteryChargeLocationChannel["CH3"] = "CH3";
    BatteryChargeLocationChannel["CH4"] = "CH4";
    BatteryChargeLocationChannel["CH5"] = "CH5";
    BatteryChargeLocationChannel["CH6"] = "CH6";
})(BatteryChargeLocationChannel || (BatteryChargeLocationChannel = {}));
export var BatteryState;
(function (BatteryState) {
    BatteryState["CHARGED"] = "CHARGED";
    BatteryState["DISCHARGED"] = "DISCHARGED";
    BatteryState["NOT_CHARGED"] = "NOT_CHARGED";
    BatteryState["PARTIAL"] = "PARTIAL";
    BatteryState["UNKNOWN"] = "UNKNOWN";
})(BatteryState || (BatteryState = {}));
export var CommentType;
(function (CommentType) {
    CommentType["ACTIVITY"] = "ACTIVITY";
    CommentType["EVENT"] = "EVENT";
    CommentType["FEMU_R_003"] = "FEMU_R_003";
    CommentType["GENERAL"] = "GENERAL";
})(CommentType || (CommentType = {}));
export var EvaReadiness;
(function (EvaReadiness) {
    EvaReadiness["BACKUP_USE_ONLY"] = "BACKUP_USE_ONLY";
    EvaReadiness["GO_FOR_EVA"] = "GO_FOR_EVA";
    EvaReadiness["NO_GO_FOR_EVA"] = "NO_GO_FOR_EVA";
    EvaReadiness["PENDING_ANALYSIS"] = "PENDING_ANALYSIS";
    EvaReadiness["REQUIRES_INSPECTION"] = "REQUIRES_INSPECTION";
})(EvaReadiness || (EvaReadiness = {}));
export var EventType;
(function (EventType) {
    EventType["EVA"] = "EVA";
    EventType["LAUNCH"] = "LAUNCH";
    EventType["RETURN"] = "RETURN";
    EventType["INCREMENT"] = "INCREMENT";
})(EventType || (EventType = {}));
export var FullPartialStatus;
(function (FullPartialStatus) {
    FullPartialStatus["FULL"] = "FULL";
    FullPartialStatus["N_A"] = "N_A";
    FullPartialStatus["PARTIAL"] = "PARTIAL";
})(FullPartialStatus || (FullPartialStatus = {}));
export var HardwareType;
(function (HardwareType) {
    HardwareType["EMU"] = "EMU";
    HardwareType["EMU_BATTERY"] = "EMU_BATTERY";
    HardwareType["EMU_BATTERY_LLB"] = "EMU_BATTERY_LLB";
    HardwareType["EMU_BIOCIDE_FILTER"] = "EMU_BIOCIDE_FILTER";
    HardwareType["EMU_FEEDWATER_SUPPLY_FILTER"] = "EMU_FEEDWATER_SUPPLY_FILTER";
    HardwareType["EMU_ION_FILTER"] = "EMU_ION_FILTER";
    HardwareType["EMU_MICRON_FILTER"] = "EMU_MICRON_FILTER";
    HardwareType["EMU_SCRUB_ASSEMBLY"] = "EMU_SCRUB_ASSEMBLY";
    HardwareType["EMU_WATER_PROCESSING_JUMPER"] = "EMU_WATER_PROCESSING_JUMPER";
    HardwareType["FPS"] = "FPS";
    HardwareType["HL_BATTERY"] = "HL_BATTERY";
    HardwareType["HUT_ORU"] = "HUT_ORU";
    HardwareType["HUT_ORU_SCRUBBER"] = "HUT_ORU_SCRUBBER";
    HardwareType["IEU_SCU"] = "IEU_SCU";
    HardwareType["LCVG"] = "LCVG";
    HardwareType["LEGACY_FPU"] = "LEGACY_FPU";
    HardwareType["LIB_CHARGER"] = "LIB_CHARGER";
    HardwareType["LPGT_BATTERY"] = "LPGT_BATTERY";
    HardwareType["LREBA"] = "LREBA";
    HardwareType["METOX"] = "METOX";
    HardwareType["METOX_SIMULATOR"] = "METOX_SIMULATOR";
    HardwareType["PGT"] = "PGT";
    HardwareType["PGT_BATTERY"] = "PGT_BATTERY";
    HardwareType["REBA"] = "REBA";
    HardwareType["SAFER"] = "SAFER";
    HardwareType["SAFER_LPGT_BATTERY"] = "SAFER_LPGT_BATTERY";
    HardwareType["SAFETY_TETHER_55"] = "SAFETY_TETHER_55";
    HardwareType["SAFETY_TETHER_85"] = "SAFETY_TETHER_85";
    HardwareType["SCOF"] = "SCOF";
    HardwareType["TETHER_EXTENSION_D_RING_EXTENDER"] = "TETHER_EXTENSION_D_RING_EXTENDER";
    HardwareType["UIA"] = "UIA";
    HardwareType["UIA_BIOCIDE_FILTER"] = "UIA_BIOCIDE_FILTER";
    HardwareType["WAIST_TETHER"] = "WAIST_TETHER";
    HardwareType["EHIP_LIGHT"] = "EHIP_LIGHT";
})(HardwareType || (HardwareType = {}));
export var IeuLocation;
(function (IeuLocation) {
    IeuLocation["NOT_INSTALLED"] = "NOT_INSTALLED";
    IeuLocation["SCU_1"] = "SCU_1";
    IeuLocation["SCU_2"] = "SCU_2";
})(IeuLocation || (IeuLocation = {}));
export var ItemScu;
(function (ItemScu) {
    ItemScu["NONE"] = "NONE";
    ItemScu["N_A"] = "N_A";
    ItemScu["SCU_1"] = "SCU_1";
    ItemScu["SCU_2"] = "SCU_2";
})(ItemScu || (ItemScu = {}));
export var LithiumBatteryChannel;
(function (LithiumBatteryChannel) {
    LithiumBatteryChannel["CHANNEL_A"] = "CHANNEL_A";
    LithiumBatteryChannel["CHANNEL_B"] = "CHANNEL_B";
    LithiumBatteryChannel["CHANNEL_1"] = "CHANNEL_1";
    LithiumBatteryChannel["CHANNEL_2"] = "CHANNEL_2";
    LithiumBatteryChannel["CHANNEL_3"] = "CHANNEL_3";
    LithiumBatteryChannel["CHANNEL_4"] = "CHANNEL_4";
    LithiumBatteryChannel["CHANNEL_5"] = "CHANNEL_5";
})(LithiumBatteryChannel || (LithiumBatteryChannel = {}));
export var LithiumBatteryChargeLocation;
(function (LithiumBatteryChargeLocation) {
    LithiumBatteryChargeLocation["OTHER"] = "OTHER";
    LithiumBatteryChargeLocation["VOLT_120"] = "VOLT_120";
    LithiumBatteryChargeLocation["VOLT_28_PSA"] = "VOLT_28_PSA";
    LithiumBatteryChargeLocation["EBOT_1"] = "EBOT_1";
    LithiumBatteryChargeLocation["EBOT_2"] = "EBOT_2";
    LithiumBatteryChargeLocation["EBOT_3"] = "EBOT_3";
})(LithiumBatteryChargeLocation || (LithiumBatteryChargeLocation = {}));
export var MaintenanceCycle;
(function (MaintenanceCycle) {
    MaintenanceCycle["NONE"] = "NONE";
    MaintenanceCycle["FPS_GREASE_CIRCULATE_INSTALLED"] = "FPS_GREASE_CIRCULATE_INSTALLED";
    MaintenanceCycle["FPS_GREASE_CIRCULATE_UNINSTALLED"] = "FPS_GREASE_CIRCULATE_UNINSTALLED";
    MaintenanceCycle["EMU_TOTAL_EVAS"] = "EMU_TOTAL_EVAS";
    MaintenanceCycle["SAFER_GN2"] = "SAFER_GN2";
    MaintenanceCycle["SAFER_PWR"] = "SAFER_PWR";
    MaintenanceCycle["SAFER_BATTERY_VOLTS"] = "SAFER_BATTERY_VOLTS";
    MaintenanceCycle["SAFER_ESTIMATED_AUTOCYCLE_RECHARGE"] = "SAFER_ESTIMATED_AUTOCYCLE_RECHARGE";
    MaintenanceCycle["SAFER_TM_CHECK_OUT"] = "SAFER_TM_CHECK_OUT";
    MaintenanceCycle["METOX_REGENERATION"] = "METOX_REGENERATION";
    MaintenanceCycle["METOX_CYCLES_04"] = "METOX_CYCLES_04";
    MaintenanceCycle["METOX_CYCLES_02_03"] = "METOX_CYCLES_02_03";
    MaintenanceCycle["METOX_CYCLES_SINCE_ORING_CHANGE"] = "METOX_CYCLES_SINCE_ORING_CHANGE";
    MaintenanceCycle["METOX_SIMULATOR_CHANGE_OUT"] = "METOX_SIMULATOR_CHANGE_OUT";
    MaintenanceCycle["LITHIUM_BATTERY_EXPIRATION"] = "LITHIUM_BATTERY_EXPIRATION";
    MaintenanceCycle["PRE_EVA_SAFER_CHECKOUT"] = "PRE_EVA_SAFER_CHECKOUT";
    MaintenanceCycle["PRE_EVA_SAFER_GN2_CHECK"] = "PRE_EVA_SAFER_GN2_CHECK";
    MaintenanceCycle["PRE_EVA_SCRUB"] = "PRE_EVA_SCRUB";
    MaintenanceCycle["POST_EVA_SCRUB"] = "POST_EVA_SCRUB";
    MaintenanceCycle["EMU_SOP_CHECK_OUT"] = "EMU_SOP_CHECK_OUT";
    MaintenanceCycle["EMU_ITEM_146_CYCLE"] = "EMU_ITEM_146_CYCLE";
    MaintenanceCycle["EMU_SCRUB_ASSEMBLY_USES"] = "EMU_SCRUB_ASSEMBLY_USES";
    MaintenanceCycle["UIA_BIOCIDE_FILTER_VOLUME"] = "UIA_BIOCIDE_FILTER_VOLUME";
    MaintenanceCycle["EMU_FEEDWATER_SUPPLY_FILTER_VOLUME"] = "EMU_FEEDWATER_SUPPLY_FILTER_VOLUME";
    MaintenanceCycle["LIB_DAYS_OVER_50_PERCENT_STATE_OF_CHARGE"] = "LIB_DAYS_OVER_50_PERCENT_STATE_OF_CHARGE";
    MaintenanceCycle["EMU_BIOCIDE_FILTER_USES"] = "EMU_BIOCIDE_FILTER_USES";
    MaintenanceCycle["SCRUB"] = "SCRUB";
    MaintenanceCycle["EHIP_LIGHT_POWER_CYCLE"] = "EHIP_LIGHT_POWER_CYCLE";
    MaintenanceCycle["EMU_PIA_EXPIRATION"] = "EMU_PIA_EXPIRATION";
    MaintenanceCycle["EMU_FULL_WATER_DUMP"] = "EMU_FULL_WATER_DUMP";
    MaintenanceCycle["EMU_PARTIAL_WATER_DUMP"] = "EMU_PARTIAL_WATER_DUMP";
    MaintenanceCycle["EMU_FIRST_SUBLIMATOR_LEAK_TEST"] = "EMU_FIRST_SUBLIMATOR_LEAK_TEST";
    MaintenanceCycle["EMU_SUBLIMATOR_LEAK_TEST"] = "EMU_SUBLIMATOR_LEAK_TEST";
    MaintenanceCycle["EMU_DCM_MWC_FLOW_TEST"] = "EMU_DCM_MWC_FLOW_TEST";
    MaintenanceCycle["HUT_WLVTA_REMOVE_REPLACE"] = "HUT_WLVTA_REMOVE_REPLACE";
    MaintenanceCycle["LOOP_SCRUB_IODINATE"] = "LOOP_SCRUB_IODINATE";
    MaintenanceCycle["LOOP_SCRUB_IODINATE_LONG"] = "LOOP_SCRUB_IODINATE_LONG";
    MaintenanceCycle["SCOF_PPRV_CYCLE"] = "SCOF_PPRV_CYCLE";
    MaintenanceCycle["LIB_CHARGER_CALIBRATION"] = "LIB_CHARGER_CALIBRATION";
    MaintenanceCycle["EMU_BATTERY_FIVE_YEAR_EXPIRATION"] = "EMU_BATTERY_FIVE_YEAR_EXPIRATION";
    MaintenanceCycle["LLB_2_BATTERY_SEVEN_YEAR_EXPIRATION"] = "LLB_2_BATTERY_SEVEN_YEAR_EXPIRATION";
    MaintenanceCycle["FIFTEEN_YEAR_EXPIRATION"] = "FIFTEEN_YEAR_EXPIRATION";
    MaintenanceCycle["BATTERY_ACTIVITY_RESET"] = "BATTERY_ACTIVITY_RESET";
    MaintenanceCycle["LEGACY_FPU_RETURN"] = "LEGACY_FPU_RETURN";
    MaintenanceCycle["LIB_AUTOCYCLE"] = "LIB_AUTOCYCLE";
    MaintenanceCycle["TAK_DATA_APPROVAL"] = "TAK_DATA_APPROVAL";
    MaintenanceCycle["TAK_DATA_COLLECTION"] = "TAK_DATA_COLLECTION";
    MaintenanceCycle["SAFER_LITHIUM_BATTERY_EXPIRATION"] = "SAFER_LITHIUM_BATTERY_EXPIRATION";
    MaintenanceCycle["EMU_WATER_SAMPLE"] = "EMU_WATER_SAMPLE";
})(MaintenanceCycle || (MaintenanceCycle = {}));
export var MetoxItemStatus;
(function (MetoxItemStatus) {
    MetoxItemStatus["REGENERATED"] = "REGENERATED";
    MetoxItemStatus["EXPENDED_50"] = "EXPENDED_50";
    MetoxItemStatus["EXPENDED"] = "EXPENDED";
    MetoxItemStatus["EXPENDED_25"] = "EXPENDED_25";
    MetoxItemStatus["EXPENDED_75"] = "EXPENDED_75";
})(MetoxItemStatus || (MetoxItemStatus = {}));
export var PgtRole;
(function (PgtRole) {
    PgtRole["AIRLOCK"] = "AIRLOCK";
    PgtRole["EV1"] = "EV1";
    PgtRole["EV2"] = "EV2";
})(PgtRole || (PgtRole = {}));
export var UserRole;
(function (UserRole) {
    UserRole["HARDWARE_ADMIN"] = "HARDWARE_ADMIN";
    UserRole["ACTIVITY_ADMIN"] = "ACTIVITY_ADMIN";
    UserRole["THRESHOLD_ALERT_ADMIN"] = "THRESHOLD_ALERT_ADMIN";
})(UserRole || (UserRole = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
    baseUrl = '';
    securityData = null;
    securityWorker;
    abortControllers = new Map();
    customFetch = (...fetchParams) => fetch(...fetchParams);
    baseApiParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };
    constructor(apiConfig = {}) {
        Object.assign(this, apiConfig);
    }
    setSecurityData = (data) => {
        this.securityData = data;
    };
    encodeQueryParam(key, value) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }
    addQueryParam(query, key) {
        return this.encodeQueryParam(key, query[key]);
    }
    addArrayQueryParam(query, key) {
        const value = query[key];
        return value.map((v) => this.encodeQueryParam(key, v)).join('&');
    }
    toQueryString(rawQuery) {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
            .join('&');
    }
    addQueryParams(rawQuery) {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }
    contentFormatters = {
        [ContentType.Json]: (input) => input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
        [ContentType.FormData]: (input) => Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            formData.append(key, property instanceof Blob
                ? property
                : typeof property === 'object' && property !== null
                    ? JSON.stringify(property)
                    : `${property}`);
            return formData;
        }, new FormData()),
        [ContentType.UrlEncoded]: (input) => this.toQueryString(input)
    };
    mergeRequestParams(params1, params2) {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }
    createAbortSignal = (cancelToken) => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }
        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };
    abortRequest = (cancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);
        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };
    request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
        const secureParams = ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
            this.securityWorker &&
            (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;
        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
        }).then(async (response) => {
            const r = response;
            r.data = null;
            r.error = null;
            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                    .then((data) => {
                    if (r.ok) {
                        r.data = data;
                    }
                    else {
                        r.error = data;
                    }
                    return r;
                })
                    .catch((e) => {
                    r.error = e;
                    return r;
                });
            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }
            if (!response.ok)
                throw data;
            return data;
        });
    };
}
/**
 * @title OOT API
 * @version v1
 *
 * Welcome to the OOT API documentation
 */
export class Api extends HttpClient {
    v1 = {
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesDetail
         * @summary Get a single activity with all itemInstances on that activity
         * @request GET:/v1/activities/{id}
         */
        activitiesDetail: (id, params = {}) => this.request({
            path: `/v1/activities/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Activity
     * @name ActivitiesUpdate
     * @summary Update top-level metadata for an activity. To update activityAttributes
    use the ActivityToItemInstanceBaseController APIs.
     * @request PUT:/v1/activities/{id}
     */
        activitiesUpdate: (id, data, params = {}) => this.request({
            path: `/v1/activities/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesDelete
         * @summary Delete an activity for all associated itemInstances.
         * @request DELETE:/v1/activities/{id}
         */
        activitiesDelete: (id, params = {}) => this.request({
            path: `/v1/activities/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesCreate
         * @summary Create an activity for one or more itemInstances
         * @request POST:/v1/activities
         */
        activitiesCreate: (data, params = {}) => this.request({
            path: `/v1/activities`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesList
         * @summary Get all activities given specific query parameters
         * @request GET:/v1/activities
         */
        activitiesList: (query, params = {}) => this.request({
            path: `/v1/activities`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesItemInstanceDelete
         * @summary Delete a specific itemInstance from an activity.
         * @request DELETE:/v1/activities/{activityId}/item-instance/{itemInstanceId}
         */
        activitiesItemInstanceDelete: (activityId, itemInstanceId, params = {}) => this.request({
            path: `/v1/activities/${activityId}/item-instance/${itemInstanceId}`,
            method: 'DELETE',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEhipLightUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/ehip_light/{itemInstanceId}
     */
        activitiesEhipLightUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/ehip_light/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuBatteryUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_battery/{itemInstanceId}
     */
        activitiesEmuBatteryUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_battery/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuBatteryLlbUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_battery_llb/{itemInstanceId}
     */
        activitiesEmuBatteryLlbUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_battery_llb/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuBiocideFilterUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_biocide_filter/{itemInstanceId}
     */
        activitiesEmuBiocideFilterUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_biocide_filter/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuFeedwaterSupplyFilterUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_feedwater_supply_filter/{itemInstanceId}
     */
        activitiesEmuFeedwaterSupplyFilterUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_feedwater_supply_filter/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuIonFilterUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_ion_filter/{itemInstanceId}
     */
        activitiesEmuIonFilterUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_ion_filter/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu/{itemInstanceId}
     */
        activitiesEmuUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuMicronFilterUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_micron_filter/{itemInstanceId}
     */
        activitiesEmuMicronFilterUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_micron_filter/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuScrubAssemblyUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_scrub_assembly/{itemInstanceId}
     */
        activitiesEmuScrubAssemblyUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_scrub_assembly/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesEmuWaterProcessingJumperUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/emu_water_processing_jumper/{itemInstanceId}
     */
        activitiesEmuWaterProcessingJumperUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/emu_water_processing_jumper/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesFpsUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/fps/{itemInstanceId}
     */
        activitiesFpsUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/fps/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesHlBatteryUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/hl_battery/{itemInstanceId}
     */
        activitiesHlBatteryUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/hl_battery/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesHutOruUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/hut_oru/{itemInstanceId}
     */
        activitiesHutOruUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/hut_oru/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesHutOruScrubberUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/hut_oru_scrubber/{itemInstanceId}
     */
        activitiesHutOruScrubberUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/hut_oru_scrubber/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesIeuScuUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/ieu_scu/{itemInstanceId}
     */
        activitiesIeuScuUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/ieu_scu/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesLcvgUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/lcvg/{itemInstanceId}
     */
        activitiesLcvgUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/lcvg/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesLegacyFpuUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/legacy_fpu/{itemInstanceId}
     */
        activitiesLegacyFpuUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/legacy_fpu/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesLibChargerUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/lib_charger/{itemInstanceId}
     */
        activitiesLibChargerUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/lib_charger/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesLpgtBatteryUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/lpgt_battery/{itemInstanceId}
     */
        activitiesLpgtBatteryUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/lpgt_battery/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesLrebaUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/lreba/{itemInstanceId}
     */
        activitiesLrebaUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/lreba/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesMetoxUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/metox/{itemInstanceId}
     */
        activitiesMetoxUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/metox/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesMetoxSimulatorUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/metox_simulator/{itemInstanceId}
     */
        activitiesMetoxSimulatorUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/metox_simulator/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesPgtBatteryUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/pgt_battery/{itemInstanceId}
     */
        activitiesPgtBatteryUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/pgt_battery/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesPgtUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/pgt/{itemInstanceId}
     */
        activitiesPgtUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/pgt/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesRebaUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/reba/{itemInstanceId}
     */
        activitiesRebaUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/reba/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesSaferUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/safer/{itemInstanceId}
     */
        activitiesSaferUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/safer/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesSaferLpgtBatteryUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/safer_lpgt_battery/{itemInstanceId}
     */
        activitiesSaferLpgtBatteryUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/safer_lpgt_battery/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesSafetyTether55Update
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/safety_tether_55/{itemInstanceId}
     */
        activitiesSafetyTether55Update: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/safety_tether_55/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesSafetyTether85Update
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/safety_tether_85/{itemInstanceId}
     */
        activitiesSafetyTether85Update: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/safety_tether_85/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesScofUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/scof/{itemInstanceId}
     */
        activitiesScofUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/scof/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesTetherExtensionDRingExtenderUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/tether_extension_d_ring_extender/{itemInstanceId}
     */
        activitiesTetherExtensionDRingExtenderUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/tether_extension_d_ring_extender/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesUiaBiocideFilterUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/uia_biocide_filter/{itemInstanceId}
     */
        activitiesUiaBiocideFilterUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/uia_biocide_filter/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesUiaUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/uia/{itemInstanceId}
     */
        activitiesUiaUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/uia/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ActivityToItemInstanceBaseController`4
     * @name ActivitiesWaistTetherUpdate
     * @summary Add or update an item instance and its activityAttributes
    on an existing activity.
     * @request PUT:/v1/activities/{activityId}/waist_tether/{itemInstanceId}
     */
        activitiesWaistTetherUpdate: (activityId, itemInstanceId, data, params = {}) => this.request({
            path: `/v1/activities/${activityId}/waist_tether/${itemInstanceId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AuditLog
         * @name AuditLogsList
         * @summary Get all audit logs
         * @request GET:/v1/audit-logs
         */
        auditLogsList: (query, params = {}) => this.request({
            path: `/v1/audit-logs`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Auth
         * @name AuthCurrentUserList
         * @summary Returns details about the currently authenticated user.
         * @request GET:/v1/auth/current-user
         */
        authCurrentUserList: (params = {}) => this.request({
            path: `/v1/auth/current-user`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsDetail
         * @summary Get a single comment
         * @request GET:/v1/comments/{id}
         */
        commentsDetail: (id, params = {}) => this.request({
            path: `/v1/comments/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsUpdate
         * @summary Update a comment
         * @request PUT:/v1/comments/{id}
         */
        commentsUpdate: (id, data, params = {}) => this.request({
            path: `/v1/comments/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsDelete
         * @summary Delete a comment
         * @request DELETE:/v1/comments/{id}
         */
        commentsDelete: (id, params = {}) => this.request({
            path: `/v1/comments/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsCreate
         * @summary Create a comment
         * @request POST:/v1/comments
         */
        commentsCreate: (data, params = {}) => this.request({
            path: `/v1/comments`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags EvaEvent
         * @name EventsEvasList
         * @summary Get all EVA events
         * @request GET:/v1/events/evas
         */
        eventsEvasList: (query, params = {}) => this.request({
            path: `/v1/events/evas`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags EvaEvent
         * @name EventsEvasCreate
         * @summary Create an EVA event
         * @request POST:/v1/events/evas
         */
        eventsEvasCreate: (data, params = {}) => this.request({
            path: `/v1/events/evas`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags EvaEvent
         * @name EventsEvasDetail
         * @summary Get a single EVA event
         * @request GET:/v1/events/evas/{id}
         */
        eventsEvasDetail: (id, query, params = {}) => this.request({
            path: `/v1/events/evas/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags EvaEvent
         * @name EventsEvasUpdate
         * @summary Update an EVA event
         * @request PUT:/v1/events/evas/{id}
         */
        eventsEvasUpdate: (id, data, params = {}) => this.request({
            path: `/v1/events/evas/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags EvaEvent
         * @name EventsEvasDelete
         * @summary Delete an EVA event
         * @request DELETE:/v1/events/evas/{id}
         */
        eventsEvasDelete: (id, params = {}) => this.request({
            path: `/v1/events/evas/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags FlightEvents
         * @name EventsFlightsList
         * @summary Get all flights (LAUNCH and RETURN event pairs)
         * @request GET:/v1/events/flights
         */
        eventsFlightsList: (query, params = {}) => this.request({
            path: `/v1/events/flights`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags FlightEvents
         * @name EventsFlightsCreate
         * @summary Create a flight (LAUNCH and RETURN event pair)
         * @request POST:/v1/events/flights
         */
        eventsFlightsCreate: (data, params = {}) => this.request({
            path: `/v1/events/flights`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags FlightEvents
         * @name EventsFlightsDetail
         * @summary Get a single flight (LAUNCH and RETURN event pair)
         * @request GET:/v1/events/flights/{eventId}
         */
        eventsFlightsDetail: (eventId, query, params = {}) => this.request({
            path: `/v1/events/flights/${eventId}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags FlightEvents
         * @name EventsFlightsUpdate
         * @summary Update a flight (LAUNCH and RETURN event pair)
         * @request PUT:/v1/events/flights/{eventId}
         */
        eventsFlightsUpdate: (eventId, data, params = {}) => this.request({
            path: `/v1/events/flights/${eventId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags FlightEvents
         * @name EventsFlightsDelete
         * @summary Delete a flight (both the LAUNCH and RETURN events)
         * @request DELETE:/v1/events/flights/{eventId}
         */
        eventsFlightsDelete: (eventId, params = {}) => this.request({
            path: `/v1/events/flights/${eventId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags IncrementEvent
         * @name EventsIncrementsList
         * @summary Get all INCREMENT events
         * @request GET:/v1/events/increments
         */
        eventsIncrementsList: (query, params = {}) => this.request({
            path: `/v1/events/increments`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags IncrementEvent
         * @name EventsIncrementsCreate
         * @summary Create an INCREMENT event
         * @request POST:/v1/events/increments
         */
        eventsIncrementsCreate: (data, params = {}) => this.request({
            path: `/v1/events/increments`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags IncrementEvent
         * @name EventsIncrementsDetail
         * @summary Get a single INCREMENT event and its EVA events
         * @request GET:/v1/events/increments/{id}
         */
        eventsIncrementsDetail: (id, params = {}) => this.request({
            path: `/v1/events/increments/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags IncrementEvent
         * @name EventsIncrementsUpdate
         * @summary Update an INCREMENT event
         * @request PUT:/v1/events/increments/{id}
         */
        eventsIncrementsUpdate: (id, data, params = {}) => this.request({
            path: `/v1/events/increments/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags IncrementEvent
         * @name EventsIncrementsDelete
         * @summary Remove all EVA events from an INCREMENT and delete the INCREMENT event
         * @request DELETE:/v1/events/increments/{id}
         */
        eventsIncrementsDelete: (id, params = {}) => this.request({
            path: `/v1/events/increments/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstance
         * @name ItemInstancesList
         * @summary Get all item instances
         * @request GET:/v1/item-instances
         */
        itemInstancesList: (query, params = {}) => this.request({
            path: `/v1/item-instances`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ItemInstance
     * @name ItemInstancesPendingMaintenanceList
     * @summary Finds all status properties on non-archived items with
    a MaintenanceCycle attribute and lists ones with dates
    occurring in the specified range.
     * @request GET:/v1/item-instances/pending-maintenance
     */
        itemInstancesPendingMaintenanceList: (query, params = {}) => this.request({
            path: `/v1/item-instances/pending-maintenance`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEhipLightList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/ehip_light
         */
        itemInstancesEhipLightList: (query, params = {}) => this.request({
            path: `/v1/item-instances/ehip_light`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEhipLightCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/ehip_light
         */
        itemInstancesEhipLightCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/ehip_light`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEhipLightDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/ehip_light/{id}
         */
        itemInstancesEhipLightDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/ehip_light/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEhipLightUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/ehip_light/{id}
         */
        itemInstancesEhipLightUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/ehip_light/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEhipLightDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/ehip_light/{id}
         */
        itemInstancesEhipLightDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/ehip_light/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_battery
         */
        itemInstancesEmuBatteryList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_battery
         */
        itemInstancesEmuBatteryCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_battery/{id}
         */
        itemInstancesEmuBatteryDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_battery/{id}
         */
        itemInstancesEmuBatteryUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_battery/{id}
         */
        itemInstancesEmuBatteryDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryLlbList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_battery_llb
         */
        itemInstancesEmuBatteryLlbList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery_llb`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryLlbCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_battery_llb
         */
        itemInstancesEmuBatteryLlbCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery_llb`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryLlbDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_battery_llb/{id}
         */
        itemInstancesEmuBatteryLlbDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery_llb/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryLlbUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_battery_llb/{id}
         */
        itemInstancesEmuBatteryLlbUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery_llb/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBatteryLlbDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_battery_llb/{id}
         */
        itemInstancesEmuBatteryLlbDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_battery_llb/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBiocideFilterList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_biocide_filter
         */
        itemInstancesEmuBiocideFilterList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_biocide_filter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBiocideFilterCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_biocide_filter
         */
        itemInstancesEmuBiocideFilterCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_biocide_filter`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBiocideFilterDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_biocide_filter/{id}
         */
        itemInstancesEmuBiocideFilterDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_biocide_filter/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBiocideFilterUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_biocide_filter/{id}
         */
        itemInstancesEmuBiocideFilterUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_biocide_filter/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuBiocideFilterDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_biocide_filter/{id}
         */
        itemInstancesEmuBiocideFilterDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_biocide_filter/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuFeedwaterSupplyFilterList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_feedwater_supply_filter
         */
        itemInstancesEmuFeedwaterSupplyFilterList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_feedwater_supply_filter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuFeedwaterSupplyFilterCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_feedwater_supply_filter
         */
        itemInstancesEmuFeedwaterSupplyFilterCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_feedwater_supply_filter`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuFeedwaterSupplyFilterDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_feedwater_supply_filter/{id}
         */
        itemInstancesEmuFeedwaterSupplyFilterDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_feedwater_supply_filter/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuFeedwaterSupplyFilterUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_feedwater_supply_filter/{id}
         */
        itemInstancesEmuFeedwaterSupplyFilterUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_feedwater_supply_filter/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuFeedwaterSupplyFilterDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_feedwater_supply_filter/{id}
         */
        itemInstancesEmuFeedwaterSupplyFilterDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_feedwater_supply_filter/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuIonFilterList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_ion_filter
         */
        itemInstancesEmuIonFilterList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_ion_filter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuIonFilterCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_ion_filter
         */
        itemInstancesEmuIonFilterCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_ion_filter`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuIonFilterDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_ion_filter/{id}
         */
        itemInstancesEmuIonFilterDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_ion_filter/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuIonFilterUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_ion_filter/{id}
         */
        itemInstancesEmuIonFilterUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_ion_filter/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuIonFilterDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_ion_filter/{id}
         */
        itemInstancesEmuIonFilterDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_ion_filter/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu
         */
        itemInstancesEmuList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu
         */
        itemInstancesEmuCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu/{id}
         */
        itemInstancesEmuDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu/{id}
         */
        itemInstancesEmuUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu/{id}
         */
        itemInstancesEmuDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuMicronFilterList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_micron_filter
         */
        itemInstancesEmuMicronFilterList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_micron_filter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuMicronFilterCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_micron_filter
         */
        itemInstancesEmuMicronFilterCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_micron_filter`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuMicronFilterDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_micron_filter/{id}
         */
        itemInstancesEmuMicronFilterDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_micron_filter/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuMicronFilterUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_micron_filter/{id}
         */
        itemInstancesEmuMicronFilterUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_micron_filter/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuMicronFilterDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_micron_filter/{id}
         */
        itemInstancesEmuMicronFilterDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_micron_filter/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuScrubAssemblyList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_scrub_assembly
         */
        itemInstancesEmuScrubAssemblyList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_scrub_assembly`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuScrubAssemblyCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_scrub_assembly
         */
        itemInstancesEmuScrubAssemblyCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_scrub_assembly`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuScrubAssemblyDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_scrub_assembly/{id}
         */
        itemInstancesEmuScrubAssemblyDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_scrub_assembly/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuScrubAssemblyUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_scrub_assembly/{id}
         */
        itemInstancesEmuScrubAssemblyUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_scrub_assembly/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuScrubAssemblyDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_scrub_assembly/{id}
         */
        itemInstancesEmuScrubAssemblyDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_scrub_assembly/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuWaterProcessingJumperList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/emu_water_processing_jumper
         */
        itemInstancesEmuWaterProcessingJumperList: (query, params = {}) => this.request({
            path: `/v1/item-instances/emu_water_processing_jumper`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuWaterProcessingJumperCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/emu_water_processing_jumper
         */
        itemInstancesEmuWaterProcessingJumperCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/emu_water_processing_jumper`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuWaterProcessingJumperDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/emu_water_processing_jumper/{id}
         */
        itemInstancesEmuWaterProcessingJumperDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_water_processing_jumper/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuWaterProcessingJumperUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/emu_water_processing_jumper/{id}
         */
        itemInstancesEmuWaterProcessingJumperUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/emu_water_processing_jumper/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesEmuWaterProcessingJumperDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/emu_water_processing_jumper/{id}
         */
        itemInstancesEmuWaterProcessingJumperDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/emu_water_processing_jumper/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesFpsList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/fps
         */
        itemInstancesFpsList: (query, params = {}) => this.request({
            path: `/v1/item-instances/fps`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesFpsCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/fps
         */
        itemInstancesFpsCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/fps`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesFpsDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/fps/{id}
         */
        itemInstancesFpsDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/fps/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesFpsUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/fps/{id}
         */
        itemInstancesFpsUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/fps/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesFpsDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/fps/{id}
         */
        itemInstancesFpsDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/fps/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHlBatteryList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/hl_battery
         */
        itemInstancesHlBatteryList: (query, params = {}) => this.request({
            path: `/v1/item-instances/hl_battery`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHlBatteryCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/hl_battery
         */
        itemInstancesHlBatteryCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/hl_battery`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHlBatteryDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/hl_battery/{id}
         */
        itemInstancesHlBatteryDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/hl_battery/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHlBatteryUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/hl_battery/{id}
         */
        itemInstancesHlBatteryUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/hl_battery/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHlBatteryDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/hl_battery/{id}
         */
        itemInstancesHlBatteryDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/hl_battery/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/hut_oru
         */
        itemInstancesHutOruList: (query, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/hut_oru
         */
        itemInstancesHutOruCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/hut_oru/{id}
         */
        itemInstancesHutOruDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/hut_oru/{id}
         */
        itemInstancesHutOruUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/hut_oru/{id}
         */
        itemInstancesHutOruDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruScrubberList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/hut_oru_scrubber
         */
        itemInstancesHutOruScrubberList: (query, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru_scrubber`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruScrubberCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/hut_oru_scrubber
         */
        itemInstancesHutOruScrubberCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru_scrubber`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruScrubberDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/hut_oru_scrubber/{id}
         */
        itemInstancesHutOruScrubberDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru_scrubber/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruScrubberUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/hut_oru_scrubber/{id}
         */
        itemInstancesHutOruScrubberUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru_scrubber/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesHutOruScrubberDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/hut_oru_scrubber/{id}
         */
        itemInstancesHutOruScrubberDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/hut_oru_scrubber/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesIeuScuList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/ieu_scu
         */
        itemInstancesIeuScuList: (query, params = {}) => this.request({
            path: `/v1/item-instances/ieu_scu`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesIeuScuCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/ieu_scu
         */
        itemInstancesIeuScuCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/ieu_scu`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesIeuScuDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/ieu_scu/{id}
         */
        itemInstancesIeuScuDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/ieu_scu/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesIeuScuUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/ieu_scu/{id}
         */
        itemInstancesIeuScuUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/ieu_scu/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesIeuScuDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/ieu_scu/{id}
         */
        itemInstancesIeuScuDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/ieu_scu/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLcvgList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/lcvg
         */
        itemInstancesLcvgList: (query, params = {}) => this.request({
            path: `/v1/item-instances/lcvg`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLcvgCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/lcvg
         */
        itemInstancesLcvgCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/lcvg`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLcvgDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/lcvg/{id}
         */
        itemInstancesLcvgDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/lcvg/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLcvgUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/lcvg/{id}
         */
        itemInstancesLcvgUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/lcvg/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLcvgDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/lcvg/{id}
         */
        itemInstancesLcvgDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/lcvg/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLegacyFpuList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/legacy_fpu
         */
        itemInstancesLegacyFpuList: (query, params = {}) => this.request({
            path: `/v1/item-instances/legacy_fpu`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLegacyFpuCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/legacy_fpu
         */
        itemInstancesLegacyFpuCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/legacy_fpu`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLegacyFpuDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/legacy_fpu/{id}
         */
        itemInstancesLegacyFpuDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/legacy_fpu/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLegacyFpuUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/legacy_fpu/{id}
         */
        itemInstancesLegacyFpuUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/legacy_fpu/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLegacyFpuDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/legacy_fpu/{id}
         */
        itemInstancesLegacyFpuDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/legacy_fpu/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLibChargerList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/lib_charger
         */
        itemInstancesLibChargerList: (query, params = {}) => this.request({
            path: `/v1/item-instances/lib_charger`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLibChargerCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/lib_charger
         */
        itemInstancesLibChargerCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/lib_charger`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLibChargerDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/lib_charger/{id}
         */
        itemInstancesLibChargerDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/lib_charger/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLibChargerUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/lib_charger/{id}
         */
        itemInstancesLibChargerUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/lib_charger/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLibChargerDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/lib_charger/{id}
         */
        itemInstancesLibChargerDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/lib_charger/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLpgtBatteryList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/lpgt_battery
         */
        itemInstancesLpgtBatteryList: (query, params = {}) => this.request({
            path: `/v1/item-instances/lpgt_battery`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLpgtBatteryCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/lpgt_battery
         */
        itemInstancesLpgtBatteryCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/lpgt_battery`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLpgtBatteryDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/lpgt_battery/{id}
         */
        itemInstancesLpgtBatteryDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/lpgt_battery/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLpgtBatteryUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/lpgt_battery/{id}
         */
        itemInstancesLpgtBatteryUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/lpgt_battery/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLpgtBatteryDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/lpgt_battery/{id}
         */
        itemInstancesLpgtBatteryDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/lpgt_battery/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLrebaList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/lreba
         */
        itemInstancesLrebaList: (query, params = {}) => this.request({
            path: `/v1/item-instances/lreba`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLrebaCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/lreba
         */
        itemInstancesLrebaCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/lreba`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLrebaDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/lreba/{id}
         */
        itemInstancesLrebaDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/lreba/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLrebaUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/lreba/{id}
         */
        itemInstancesLrebaUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/lreba/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesLrebaDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/lreba/{id}
         */
        itemInstancesLrebaDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/lreba/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/metox
         */
        itemInstancesMetoxList: (query, params = {}) => this.request({
            path: `/v1/item-instances/metox`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/metox
         */
        itemInstancesMetoxCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/metox`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/metox/{id}
         */
        itemInstancesMetoxDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/metox/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/metox/{id}
         */
        itemInstancesMetoxUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/metox/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/metox/{id}
         */
        itemInstancesMetoxDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/metox/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxSimulatorList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/metox_simulator
         */
        itemInstancesMetoxSimulatorList: (query, params = {}) => this.request({
            path: `/v1/item-instances/metox_simulator`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxSimulatorCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/metox_simulator
         */
        itemInstancesMetoxSimulatorCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/metox_simulator`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxSimulatorDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/metox_simulator/{id}
         */
        itemInstancesMetoxSimulatorDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/metox_simulator/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxSimulatorUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/metox_simulator/{id}
         */
        itemInstancesMetoxSimulatorUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/metox_simulator/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesMetoxSimulatorDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/metox_simulator/{id}
         */
        itemInstancesMetoxSimulatorDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/metox_simulator/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtBatteryList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/pgt_battery
         */
        itemInstancesPgtBatteryList: (query, params = {}) => this.request({
            path: `/v1/item-instances/pgt_battery`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtBatteryCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/pgt_battery
         */
        itemInstancesPgtBatteryCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/pgt_battery`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtBatteryDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/pgt_battery/{id}
         */
        itemInstancesPgtBatteryDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/pgt_battery/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtBatteryUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/pgt_battery/{id}
         */
        itemInstancesPgtBatteryUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/pgt_battery/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtBatteryDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/pgt_battery/{id}
         */
        itemInstancesPgtBatteryDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/pgt_battery/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/pgt
         */
        itemInstancesPgtList: (query, params = {}) => this.request({
            path: `/v1/item-instances/pgt`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/pgt
         */
        itemInstancesPgtCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/pgt`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/pgt/{id}
         */
        itemInstancesPgtDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/pgt/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/pgt/{id}
         */
        itemInstancesPgtUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/pgt/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesPgtDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/pgt/{id}
         */
        itemInstancesPgtDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/pgt/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesRebaList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/reba
         */
        itemInstancesRebaList: (query, params = {}) => this.request({
            path: `/v1/item-instances/reba`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesRebaCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/reba
         */
        itemInstancesRebaCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/reba`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesRebaDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/reba/{id}
         */
        itemInstancesRebaDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/reba/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesRebaUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/reba/{id}
         */
        itemInstancesRebaUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/reba/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesRebaDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/reba/{id}
         */
        itemInstancesRebaDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/reba/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/safer
         */
        itemInstancesSaferList: (query, params = {}) => this.request({
            path: `/v1/item-instances/safer`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/safer
         */
        itemInstancesSaferCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/safer`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/safer/{id}
         */
        itemInstancesSaferDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/safer/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/safer/{id}
         */
        itemInstancesSaferUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/safer/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/safer/{id}
         */
        itemInstancesSaferDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/safer/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferLpgtBatteryList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/safer_lpgt_battery
         */
        itemInstancesSaferLpgtBatteryList: (query, params = {}) => this.request({
            path: `/v1/item-instances/safer_lpgt_battery`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferLpgtBatteryCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/safer_lpgt_battery
         */
        itemInstancesSaferLpgtBatteryCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/safer_lpgt_battery`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferLpgtBatteryDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/safer_lpgt_battery/{id}
         */
        itemInstancesSaferLpgtBatteryDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/safer_lpgt_battery/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferLpgtBatteryUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/safer_lpgt_battery/{id}
         */
        itemInstancesSaferLpgtBatteryUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/safer_lpgt_battery/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSaferLpgtBatteryDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/safer_lpgt_battery/{id}
         */
        itemInstancesSaferLpgtBatteryDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/safer_lpgt_battery/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether55List
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/safety_tether_55
         */
        itemInstancesSafetyTether55List: (query, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_55`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether55Create
         * @summary Create an item instance
         * @request POST:/v1/item-instances/safety_tether_55
         */
        itemInstancesSafetyTether55Create: (data, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_55`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether55Detail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/safety_tether_55/{id}
         */
        itemInstancesSafetyTether55Detail: (id, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_55/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether55Update
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/safety_tether_55/{id}
         */
        itemInstancesSafetyTether55Update: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_55/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether55Delete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/safety_tether_55/{id}
         */
        itemInstancesSafetyTether55Delete: (id, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_55/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether85List
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/safety_tether_85
         */
        itemInstancesSafetyTether85List: (query, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_85`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether85Create
         * @summary Create an item instance
         * @request POST:/v1/item-instances/safety_tether_85
         */
        itemInstancesSafetyTether85Create: (data, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_85`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether85Detail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/safety_tether_85/{id}
         */
        itemInstancesSafetyTether85Detail: (id, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_85/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether85Update
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/safety_tether_85/{id}
         */
        itemInstancesSafetyTether85Update: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_85/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesSafetyTether85Delete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/safety_tether_85/{id}
         */
        itemInstancesSafetyTether85Delete: (id, params = {}) => this.request({
            path: `/v1/item-instances/safety_tether_85/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesScofList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/scof
         */
        itemInstancesScofList: (query, params = {}) => this.request({
            path: `/v1/item-instances/scof`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesScofCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/scof
         */
        itemInstancesScofCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/scof`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesScofDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/scof/{id}
         */
        itemInstancesScofDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/scof/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesScofUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/scof/{id}
         */
        itemInstancesScofUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/scof/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesScofDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/scof/{id}
         */
        itemInstancesScofDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/scof/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesTetherExtensionDRingExtenderList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/tether_extension_d_ring_extender
         */
        itemInstancesTetherExtensionDRingExtenderList: (query, params = {}) => this.request({
            path: `/v1/item-instances/tether_extension_d_ring_extender`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesTetherExtensionDRingExtenderCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/tether_extension_d_ring_extender
         */
        itemInstancesTetherExtensionDRingExtenderCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/tether_extension_d_ring_extender`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesTetherExtensionDRingExtenderDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/tether_extension_d_ring_extender/{id}
         */
        itemInstancesTetherExtensionDRingExtenderDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/tether_extension_d_ring_extender/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesTetherExtensionDRingExtenderUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/tether_extension_d_ring_extender/{id}
         */
        itemInstancesTetherExtensionDRingExtenderUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/tether_extension_d_ring_extender/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesTetherExtensionDRingExtenderDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/tether_extension_d_ring_extender/{id}
         */
        itemInstancesTetherExtensionDRingExtenderDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/tether_extension_d_ring_extender/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaBiocideFilterList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/uia_biocide_filter
         */
        itemInstancesUiaBiocideFilterList: (query, params = {}) => this.request({
            path: `/v1/item-instances/uia_biocide_filter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaBiocideFilterCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/uia_biocide_filter
         */
        itemInstancesUiaBiocideFilterCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/uia_biocide_filter`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaBiocideFilterDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/uia_biocide_filter/{id}
         */
        itemInstancesUiaBiocideFilterDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/uia_biocide_filter/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaBiocideFilterUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/uia_biocide_filter/{id}
         */
        itemInstancesUiaBiocideFilterUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/uia_biocide_filter/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaBiocideFilterDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/uia_biocide_filter/{id}
         */
        itemInstancesUiaBiocideFilterDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/uia_biocide_filter/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/uia
         */
        itemInstancesUiaList: (query, params = {}) => this.request({
            path: `/v1/item-instances/uia`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/uia
         */
        itemInstancesUiaCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/uia`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/uia/{id}
         */
        itemInstancesUiaDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/uia/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/uia/{id}
         */
        itemInstancesUiaUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/uia/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesUiaDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/uia/{id}
         */
        itemInstancesUiaDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/uia/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesWaistTetherList
         * @summary Get all item instances of this type
         * @request GET:/v1/item-instances/waist_tether
         */
        itemInstancesWaistTetherList: (query, params = {}) => this.request({
            path: `/v1/item-instances/waist_tether`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesWaistTetherCreate
         * @summary Create an item instance
         * @request POST:/v1/item-instances/waist_tether
         */
        itemInstancesWaistTetherCreate: (data, params = {}) => this.request({
            path: `/v1/item-instances/waist_tether`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesWaistTetherDetail
         * @summary Get a single item instance
         * @request GET:/v1/item-instances/waist_tether/{id}
         */
        itemInstancesWaistTetherDetail: (id, params = {}) => this.request({
            path: `/v1/item-instances/waist_tether/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesWaistTetherUpdate
         * @summary Update an item instance
         * @request PUT:/v1/item-instances/waist_tether/{id}
         */
        itemInstancesWaistTetherUpdate: (id, data, params = {}) => this.request({
            path: `/v1/item-instances/waist_tether/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ItemInstanceBaseController`4
         * @name ItemInstancesWaistTetherDelete
         * @summary Delete an item instance
         * @request DELETE:/v1/item-instances/waist_tether/{id}
         */
        itemInstancesWaistTetherDelete: (id, params = {}) => this.request({
            path: `/v1/item-instances/waist_tether/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags MaintenanceInterval
         * @name MaintenanceIntervalsList
         * @summary Get a list of all maintenance intervals with their requirements and warning thresholds
         * @request GET:/v1/maintenance-intervals
         */
        maintenanceIntervalsList: (params = {}) => this.request({
            path: `/v1/maintenance-intervals`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags MaintenanceInterval
         * @name MaintenanceIntervalsUpdate
         * @summary Update the warning threshold of a maintenance interval
         * @request PUT:/v1/maintenance-intervals
         */
        maintenanceIntervalsUpdate: (data, params = {}) => this.request({
            path: `/v1/maintenance-intervals`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags User
         * @name UsersUpdate
         * @summary Update a single user by ID.
         * @request PUT:/v1/users/{id}
         */
        usersUpdate: (id, data, params = {}) => this.request({
            path: `/v1/users/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags User
         * @name UsersList
         * @summary Get users.
         * @request GET:/v1/users
         */
        usersList: (query, params = {}) => this.request({
            path: `/v1/users`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    health = {
        /**
         * No description
         *
         * @tags Health
         * @name HealthList
         * @request GET:/health
         */
        healthList: (params = {}) => this.request({
            path: `/health`,
            method: 'GET',
            ...params
        })
    };
}
